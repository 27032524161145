import * as React from 'react';
import styled from 'styled-components';
import { ConnectedSocketClient } from '../../../shared/socket/client';
import { LogoIcon } from '../icons/LogoIcon';

const StyledConnectedClient = styled.div<{ color: string }>`
  display: inline-block;
  border-radius: 100%;
  overflow: hidden;
  background: white;
  border: 2px solid ${(props) => props.theme.background};
  background-color: ${(props) => props.color};
  margin-right: -10px;
  transition: border 0.5s ease-in-out;
  width: 36px;
  height: 36px;
  text-align: center;
  padding-top: 5px;

  &:last-of-type {
    margin-right: 0;
  }

  svg {
    width: 22px;
    height: 22px;

    path {
      fill: white;
    }
  }
`;

interface Props {
  client: ConnectedSocketClient;
}

export const ConnectedClient: React.FunctionComponent<Props> = ({ client }) => {
  return (
    <StyledConnectedClient color={client.color}>
      <LogoIcon />
    </StyledConnectedClient>
  );
};
