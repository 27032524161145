import { BaseAction } from '..';
import { EditorState } from 'draft-js';
import { Document } from '../../../shared/document';

export interface NewDocumentEditorStateUpdated
  extends BaseAction<'NEW_DOCUMENT_EDITOR_STATE_UPDATED'> {
  editorState: EditorState;
}
export function newDocumentEditorStateUpdated(
  editorState: EditorState,
): NewDocumentEditorStateUpdated {
  return {
    type: 'NEW_DOCUMENT_EDITOR_STATE_UPDATED',
    editorState,
  };
}

export type NewDocumentShareStarted = BaseAction<'NEW_DOCUMENT_SHARE_STARTED'>;
export function newDocumentShareStarted(): NewDocumentShareStarted {
  return {
    type: 'NEW_DOCUMENT_SHARE_STARTED',
  };
}

export interface NewDocumentShareSuccess extends BaseAction<'NEW_DOCUMENT_SHARE_SUCCESS'> {
  document: Document;
}
export function newDocumentShareSuccess(document: Document): NewDocumentShareSuccess {
  return {
    type: 'NEW_DOCUMENT_SHARE_SUCCESS',
    document,
  };
}

export type NewDocumentAction =
  | NewDocumentEditorStateUpdated
  | NewDocumentShareStarted
  | NewDocumentShareSuccess;
