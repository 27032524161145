function isPrimitive(value: any): boolean {
  return typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean';
}

// Deep equal is designed to work with plain objects, arrays and primitive values
// Not dates, classes or other exotic things
export function deepEqual(val1: any, val2: any): boolean {
  if (val1 === val2) {
    return true;
  }
  if (val1 === null || val2 === null || val1 === undefined || val2 === undefined) {
    return false; // we've already done strict equality
  }
  if (isPrimitive(val1) || isPrimitive(val2)) {
    return false; // we've already done strict equality
  }

  // Array Comparison
  if (Array.isArray(val1) && Array.isArray(val2)) {
    if (val1.length !== val2.length) {
      return false;
    }
    for (let i = 0; i < val1.length; i++) {
      if (!deepEqual(val1[i], val2[i])) {
        return false;
      }
    }
    return true;
  }

  // Object Comparison
  const oneProps = Object.getOwnPropertyNames(val1);
  const twoProps = Object.getOwnPropertyNames(val2);

  // Short-circuit if number of elements/properties is not equal
  if (oneProps.length !== twoProps.length) {
    return false;
  }
  // Sort the properties so we can compare efficiently
  oneProps.sort();
  twoProps.sort();

  // Check they have the same set of properties
  for (let i = 0; i < oneProps.length; i++) {
    if (oneProps[i] !== twoProps[i]) {
      return false;
    }
  }

  // Check their properties have the same values
  for (const prop of oneProps) {
    if (!deepEqual(val1[prop], val2[prop])) {
      return false;
    }
  }
  return true;
}
