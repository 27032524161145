import { Dictionary } from './Dictionary';

export enum LogLevel {
  DEBUG,
  INFO,
  WARN,
  ERROR,
  FATAL,
}

export interface SpecialLoggerFlags {
  // Formats the console output as a table (if avalible)
  formatAsTable?: boolean;
}

export type LoggerArgsDict = Dictionary<Object | undefined | null> & SpecialLoggerFlags;
export type LoggerArgs = LoggerArgsDict | null;

export interface LoggerLike {
  debug(args: LoggerArgs, message: string): void;
  info(args: LoggerArgs, message: string): void;
  warn(args: LoggerArgs, message: string): void;
  error(args: LoggerArgs, message: string): void;
  fatal(args: LoggerArgs, message: string): void;
}

export class Logger implements LoggerLike {
  private level: LogLevel;
  private consoleLogging: boolean;

  public constructor() {
    this.level = LogLevel.INFO;
    this.consoleLogging = false;
  }

  public debug(args: LoggerArgs, message: string): void {
    if (typeof console.table === 'function' && args && args.formatAsTable) {
      this.consoleTable(message, args);
    } else {
      this.log(LogLevel.DEBUG, message, args);
    }
  }

  public info(args: LoggerArgs, message: string): void {
    this.log(LogLevel.INFO, message, args);
  }
  public warn(args: LoggerArgs, message: string): void {
    this.log(LogLevel.WARN, message, args);
  }
  public error(args: LoggerArgs, message: string): void {
    this.log(LogLevel.ERROR, message, args);
  }
  public fatal(args: LoggerArgs, message: string): void {
    this.log(LogLevel.FATAL, message, args);
  }

  public getLevel(): LogLevel {
    return this.level;
  }

  public setLevel(level: LogLevel): void {
    this.level = level;
  }

  public enableConsoleLogging(): void {
    this.consoleLogging = true;
  }

  private consoleTable(message: string, args: LoggerArgsDict): void {
    if (!this.shouldLog(LogLevel.DEBUG)) {
      /** Don't log messages of precedence than the current logger level */
      return;
    }
    const { formatAsTable, ...rest } = args;
    console.log(message);
    console.table(rest);
  }

  private shouldLog(level: LogLevel): boolean {
    return level >= this.level;
  }

  private log(level: LogLevel, message: string, args: LoggerArgs): void {
    if (!this.shouldLog(level)) {
      /** Don't log messages of precedence than the current logger level */
      return;
    }
    this.consoleLog(level, message, args);
  }

  private consoleLog(level: LogLevel, message: string, args?: LoggerArgs): void {
    if (!this.consoleLogging) {
      return;
    }
    message += message.match('\n$') ? '' : '\n';
    /* tslint:disable:no-console */
    switch (level) {
      default:
      case LogLevel.DEBUG:
        console.log(message, args);
        break;
      case LogLevel.INFO:
        console.info(message, args);
        break;
      case LogLevel.WARN:
        console.warn(message, args);
        break;
      case LogLevel.ERROR:
      case LogLevel.FATAL:
        console.error(message, args);
        break;
    }
    /* tslint:enable:no-console */
  }
}

export const logger = new Logger();
logger.enableConsoleLogging();
