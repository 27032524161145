import { CompositeDecorator } from 'draft-js';
import { checkboxDecorator } from './decorators/CheckboxDecorator';
import { entityLinkDecorator } from './decorators/EntityLinkDecorator';
import { rawLinkDecorator } from './decorators/RawLinkDecorator';

export const inlineDraftDecorator = new CompositeDecorator([
  checkboxDecorator,
  entityLinkDecorator,
  rawLinkDecorator,
]);
