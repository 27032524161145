import lscache from 'lscache';
import { AppAction, AppState } from '..';
import { update } from '../../lib/updater';
import { apiClient } from '../../api/InlineAPIClient';

const AUTH_TOKEN_CACHE_KEY = 'authToken';

export interface AuthenticationState {
  token: string | null;
}

export function initialAuthenticationState(): AuthenticationState {
  const persistedToken: string | undefined = lscache.get(AUTH_TOKEN_CACHE_KEY);
  if (persistedToken) {
    apiClient.setToken(persistedToken);
  }
  return {
    token: persistedToken || null,
  };
}

export function authenticationReducer(
  state: AuthenticationState,
  action: AppAction,
): AuthenticationState {
  switch (action.type) {
    case 'AUTHENTICATION_TOKEN_RECEIVED':
      lscache.set(AUTH_TOKEN_CACHE_KEY, action.token);
      apiClient.setToken(action.token);
      return update(state)
        .get('token')
        .set(() => action.token);
    default:
      return state;
  }
}

export const authenticationTokenSelector = (state: AppState) => state.authentication.token;
