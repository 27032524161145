import { EditorState, ContentState, convertFromRaw, ContentBlock, EntityInstance } from 'draft-js';
import {
  ContentStateDiff,
  DocumentBlock,
  DocumentLayoutDiff,
  BlockEntity,
} from '../../shared/document';
import { Dictionary } from '../lib/Dictionary';

export const applyContentStateDiff = (diff: ContentStateDiff) => (
  editorState: EditorState | null,
): EditorState | null => {
  if (editorState === null) {
    return editorState;
  }
  const currentContentState = editorState.getCurrentContent();
  let exisitingBlocks = currentContentState.getBlocksAsArray();

  const entityMap = currentContentState.getEntityMap();
  const exisitingEntityMap: Dictionary<BlockEntity<any>> = {};
  entityMap.__getAll().map((entity: any, key: string) => {
    exisitingEntityMap[key] = {
      type: entity.getType(),
      mutability: entity.getMutability(),
      data: entity.getData(),
    };
  });

  const { blockMap: builtBlockMap, entities: builtEntities } = buildDraftBlockMap(
    diff.blocks,
    exisitingEntityMap,
  );
  const layoutChanges = buildLayoutChanges(diff.layout);

  const addedEntries = Array.from(layoutChanges.added);
  while (addedEntries.length > 0) {
    const [afterKey, newBlockKey] = addedEntries.shift()!;
    const index =
      afterKey === null ? 0 : exisitingBlocks.findIndex((b) => b.getKey() === afterKey) + 1 || null;
    if (index !== null) {
      const newBlock = builtBlockMap.get(newBlockKey)!;
      exisitingBlocks.splice(index, 0, newBlock);
    } else {
      addedEntries.push([afterKey, newBlockKey]);
    }
  }

  exisitingBlocks = exisitingBlocks
    .filter((b) => !layoutChanges.removed.has(b.getKey()))
    .map((block) => {
      const updatedBlock = builtBlockMap.get(block.getKey());
      return updatedBlock || block;
    });

  const newContentState = ContentState.createFromBlockArray(exisitingBlocks, builtEntities);

  return EditorState.set(editorState, {
    currentContent: newContentState,
  });
};

function buildDraftBlockMap(
  blocks: DocumentBlock[],
  entityMap: Dictionary<BlockEntity<any>>,
): { blockMap: Map<string, ContentBlock>; entities: Map<string, EntityInstance> } {
  blocks.forEach((block) => {
    entityMap = {
      ...entityMap,
      ...block.entityMap,
    };
  });
  const fakeContentState = convertFromRaw({
    blocks: blocks as any,
    entityMap,
  });

  return {
    blockMap: new Map(
      blocks.map((block) => [block.key, fakeContentState.getBlockForKey(block.key)]),
    ),
    entities: fakeContentState.getEntityMap().__getAll(),
  };
}

function buildLayoutChanges(
  layoutDiff: DocumentLayoutDiff | null,
): { added: Map<string | null, string>; removed: Set<string> } {
  if (layoutDiff === null) {
    return {
      added: new Map(),
      removed: new Set(),
    };
  }

  return {
    added: new Map(layoutDiff.added),
    removed: new Set(layoutDiff.removed),
  };
}
