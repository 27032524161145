import lscache from 'lscache';
import { AppAction, AppState } from '..';
import { update } from '../../lib/updater';
import { EditorState, convertFromRaw, convertToRaw, RawDraftContentState } from 'draft-js';
import { logger } from '../../lib/Logger';
import { notMissing } from '../../lib/typeGuards';
import { inlineDraftDecorator } from '../../draft/decorator';

const NEW_DOCUMENT_CACHE_KEY = 'new-document';

interface PersistedNewDocumentState {
  editorState: RawDraftContentState;
}

export interface NewDocumentState {
  editorState: EditorState;
  isSharing: boolean;
}

export function initialNewDocumentState(): NewDocumentState {
  const persistedState = lscache.get(NEW_DOCUMENT_CACHE_KEY) as PersistedNewDocumentState | null;
  try {
    if (notMissing(persistedState)) {
      return {
        editorState: EditorState.set(
          EditorState.createWithContent(
            convertFromRaw(persistedState.editorState),
            inlineDraftDecorator,
          ),
          {
            allowUndo: false,
          },
        ),
        isSharing: false,
      };
    }
  } catch (e) {
    logger.error({ e, persistedState }, 'Failed to load persisted new document');
  }

  return {
    editorState: EditorState.createEmpty(inlineDraftDecorator),
    isSharing: false,
  };
}

export function newDocumentReducer(state: NewDocumentState, action: AppAction): NewDocumentState {
  switch (action.type) {
    case 'NEW_DOCUMENT_EDITOR_STATE_UPDATED':
      const stateWithEditorState = update(state)
        .get('editorState')
        .set(() => action.editorState);
      persistNewDocumentState(stateWithEditorState);
      return stateWithEditorState;
    case 'NEW_DOCUMENT_SHARE_STARTED':
      return update(state)
        .get('isSharing')
        .set(() => true);
    case 'NEW_DOCUMENT_SHARE_SUCCESS':
      clearNewDocumentState();
      return initialNewDocumentState();
    default:
      return state;
  }
}

export const newDocumentSelector = (state: AppState) => state.newDocument;
export const newDocumentEditorStateSelector = (state: AppState) => state.newDocument.editorState;
export const newDocumentIsSharingSelector = (state: AppState) => state.newDocument.isSharing;

function clearNewDocumentState(): void {
  lscache.remove(NEW_DOCUMENT_CACHE_KEY);
}

function persistNewDocumentState(state: NewDocumentState): void {
  const persistedState: PersistedNewDocumentState = {
    editorState: convertToRaw(state.editorState.getCurrentContent()),
  };

  lscache.set(NEW_DOCUMENT_CACHE_KEY, persistedState);
}
