import axios, { AxiosResponse, AxiosError } from 'axios';
import * as qs from 'qs';
import { CreateDocumentDTO, GetDocumentIdDTO, BaseControllerDTO } from '../../shared/api/dtos';
import { notMissing } from '../lib/typeGuards';
import { DocumentBlock } from '../../shared/document';
import { Dictionary } from '../lib/Dictionary';

enum HTTPMethod {
  GET = 'get',
  POST = 'post',
  PATCH = 'patch',
  DELETE = 'delete',
}

interface AxiosResponseError extends AxiosError {
  response: AxiosResponse;
}

export function isResponseError(e: any): e is AxiosResponseError {
  return 'config' in e && 'response' in e;
}

export function extractMessageAndPath(e: AxiosResponseError): [string, string | undefined] {
  return [
    e.response && 'errorMessage' in e.response.data
      ? e.response.data.errorMessage
      : 'An unexpected error occurred',
    e.response && 'path' in e.response.data ? e.response.data.path : undefined,
  ];
}

export class InlineAPIClient {
  private token: string | undefined;

  public constructor(public baseUrl: string) {}

  public setToken(token: string): void {
    this.token = token;
  }

  public clearToken(): void {
    this.token = undefined;
  }

  public async createDocument(
    blocks: DocumentBlock[],
  ): Promise<CreateDocumentDTO['response']> {
    const response = await this.sendRequest<CreateDocumentDTO>(HTTPMethod.POST, `/documents`, {
      blocks,
    });

    return response.data;
  }

  public async resolveDocumentId(uri: string): Promise<GetDocumentIdDTO['response']> {
    const response = await this.sendRequest<GetDocumentIdDTO>(
      HTTPMethod.GET,
      `/documents/id-resolver/${uri}`,
    );
    return response.data;
  }

  private async sendRequest<D extends BaseControllerDTO>(
    method: HTTPMethod,
    endpoint: string,
    data?: D['request'],
  ): Promise<AxiosResponse<D['response']>> {
    const headers: Dictionary<string> = {};

    if (this.token) {
      headers.authorization = `Bearer ${this.token}`;
    }

    return await axios.request({
      method,
      url: `${this.baseUrl}${endpoint}`,
      data,
      headers,
    });
  }
}

export const apiClient = new InlineAPIClient(process.env.API_URL!);
