import React from 'react';
import styled from 'styled-components';
import { DraftDecorator, ContentBlock } from 'draft-js';
import { DocumentBlockType } from '../../../shared/document';

type Cb = (start: number, end: number) => void;

const strategy = (contentBlock: ContentBlock, callback: Cb) => {
  const type = contentBlock.getType() as DocumentBlockType;
  if ([DocumentBlockType.UNORDERED_LIST_ITEM, DocumentBlockType.ORDERED_LIST_ITEM].includes(type)) {
    const text = contentBlock.getText().slice(0, 3);
    if (text === '[x]' || text === '[ ]') {
      callback(0, 3);
    }
  }
};

const CheckboxDecoratorStyled = styled.span`
  cursor: pointer;
`;

interface Props {
  decoratedText: string;
  blockKey: string;
}

export const CheckboxDecorator: React.FunctionComponent<Props> = (props) => {
  const isChecked = props.decoratedText === '[x]';

  const click = (e: React.MouseEvent) => {
    e.preventDefault();
    // const contentState = props.getEditorState().getCurrentContent();
    // const previousSelection = props.getEditorState().getSelection();
    // const checkboxSelection = SelectionState.createEmpty(props.blockKey).merge({
    //   anchorOffset: 1,
    //   focusOffset: 2,
    // }) as SelectionState;
    // const checkedContentState = Modifier.replaceText(
    //   contentState,
    //   checkboxSelection,
    //   isChecked ? ' ' : 'x',
    // );
    // const checkedEditorState = EditorState.push(
    //   props.getEditorState(),
    //   checkedContentState,
    //   'spellcheck-change',
    // );
    // props.setEditorState(EditorState.forceSelection(checkedEditorState, previousSelection));
  };

  return <CheckboxDecoratorStyled onMouseDown={click}>{props.children}</CheckboxDecoratorStyled>;
};

export const checkboxDecorator: DraftDecorator = {
  strategy,
  component: CheckboxDecorator,
};
