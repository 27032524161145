import * as React from 'react';
import styled from 'styled-components';
import { Link } from './Link';
import { LogoIcon } from './icons/LogoIcon';
import { lighten } from 'polished';

const PrimaryLogoStyled = styled.div`
  position: fixed;
  top: 32px;
  left: 32px;
  z-index: 10;

  svg {
    width: 36px;
    height: 36px;
    fill: ${(props) => props.theme.primary};

    transition: transform 0.2s linear, fill 0.2s linear;
  }

  a:hover svg {
    transform: scale(1.2);
    fill: ${(props) => lighten(0.08)(props.theme.primary)};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    top: 16px;
    left: 16px;
  }
`;

interface Props {}

export const PrimaryLogo: React.FunctionComponent<Props> = (props) => {
  return (
    <PrimaryLogoStyled>
      <Link href='/'>
        <LogoIcon />
      </Link>
    </PrimaryLogoStyled>
  );
};
