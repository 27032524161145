import { BaseAction } from '..';
import { ThemeName } from '../../themes/ThemeName';

export interface AppSettingsThemeChanged extends BaseAction<'APP_SETTINGS_THEME_CHANGED'> {
  theme: ThemeName;
}

export function appSettingsThemeChanged(theme: ThemeName): AppSettingsThemeChanged {
  return {
    type: 'APP_SETTINGS_THEME_CHANGED',
    theme,
  };
}

export type AppSettingsAction = AppSettingsThemeChanged;
