import * as React from 'react';
import styled from 'styled-components';
import { ContentBlock, EditorBlock } from 'draft-js';
import { ConnectedSocketClient } from '../../../shared/socket/client';
import { LogoIcon } from '../icons/LogoIcon';
import { LockIcon } from '../icons/LockIcon';

const LockDisplay = styled.div<{ color: string }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  opacity: 0;
  background-color: ${(props) => props.color};
  transition: opacity 0.1s ease-in-out;
`;

const ClientLockAvatar = styled.div<{ color: string }>`
  position: absolute;
  top: -2px;
  left: -53px;
  border-radius: 100%;
  background-color: ${(props) => props.color};
  width: 27px;
  height: 27px;
  text-align: center;
  padding-top: 4px;

  svg:first-of-type {
    width: 17px;
    height: 17px;

    path {
      fill: white;
    }
  }

  svg:last-of-type {
    position: absolute;
    bottom: -5px;
    right: -5px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    transform: scale(0.8);
    top: -2px;
    right: -27px;
    left: unset;
  }
`;

const LockMessage = styled.div`
  position: absolute;
  top: 0;
  left: -192px;
  width: 182px;
  font-size: 12px;
  text-align: left;
  line-height: 16px;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: ${(props) => props.theme.tooltip.background};
  color: ${(props) => props.theme.tooltip.text};
  transition: opacity 0.1s ease-in-out;
  opacity: 0;

  @media (max-width: ${(props) => props.theme.breakpoints.narrow}px) {
    display: none;
  }
`;

const StyledEditorBlock = styled.div`
  &:hover ${LockDisplay} {
    opacity: 0.2;
  }

  &:hover ${LockMessage} {
    opacity: 1;
  }
`;

export const WrappedEditorBlock: React.FunctionComponent<{ block: ContentBlock }> = (props) => {
  const lock: ConnectedSocketClient | null = props.block.getData().get('lock', null);
  return (
    <StyledEditorBlock>
      {lock ? (
        <>
          <LockDisplay color={lock.color} />
          <ClientLockAvatar color={lock.color}>
            <LogoIcon />
            <LockIcon />
            <LockMessage>Another user is editing this block right now</LockMessage>
          </ClientLockAvatar>
        </>
      ) : null}
      <EditorBlock {...props} />
    </StyledEditorBlock>
  );
};
