import { BaseAction } from '..';
import { ThemeName } from '../../themes/ThemeName';

export interface AuthenticationTokenReceived extends BaseAction<'AUTHENTICATION_TOKEN_RECEIVED'> {
  token: string;
}

export function authenticationTokenReceived(token: string): AuthenticationTokenReceived {
  return {
    type: 'AUTHENTICATION_TOKEN_RECEIVED',
    token,
  };
}

export type AuthenticationAction = AuthenticationTokenReceived;
