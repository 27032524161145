import * as React from 'react';
import styled from 'styled-components';
import { PrimaryLogo } from '../components/PrimaryLogo';
import { SettingsOpener } from '../components/SettingsOpener';
import { ShareLocalButton } from '../components/ShareLocalButton';
import { EditorWrapper } from '../components/EditorWrapper';
import { DocumentBody } from '../components/document/DocumentBody';
import { useSelector, useDispatch } from '../redux';
import { newDocumentEditorStateSelector } from '../redux/reducers/newDocument';
import { newDocumentEditorStateUpdated } from '../redux/actions/newDocument';
import { Editor, EditorState, SelectionState } from 'draft-js';
import { Helmet } from 'react-helmet';

const NewDocumentPageStyled = styled.div``;

interface Props {}

export const NewDocumentPage: React.FunctionComponent<Props> = (props) => {
  const documentBodyRef = React.useRef<Editor>(null);
  const editorState = useSelector(newDocumentEditorStateSelector);
  const dispatch = useDispatch();

  const documentEmpty = editorState.getCurrentContent().getPlainText().trim().length < 5;

  const moveToBody = () => {
    const block = editorState.getCurrentContent().getFirstBlock();
    const selection = SelectionState.createEmpty(block.getKey());
    dispatch(newDocumentEditorStateUpdated(EditorState.forceSelection(editorState, selection)));
  };

  return (
    <NewDocumentPageStyled>
      <Helmet title='inline' />
      <PrimaryLogo />
      <ShareLocalButton documentEmpty={documentEmpty} />
      <SettingsOpener />
      <EditorWrapper>
        <DocumentBody
          ref={documentBodyRef}
          editorKey='newDocument'
          editorState={editorState}
          onChange={(state) => dispatch(newDocumentEditorStateUpdated(state))}
        />
      </EditorWrapper>
    </NewDocumentPageStyled>
  );
};
