import * as React from 'react';
import history from '../history';
import { useSelector } from '../redux';
import { rawPathSelector } from '../redux/reducers/router';

interface Props {
  url: string;
  addFrom?: boolean;
}

export const RedirectPage: React.FunctionComponent<Props> = (props) => {
  const currentUrl = useSelector(rawPathSelector);
  React.useEffect(() => {
    if (props.addFrom) {
      history.replace(`${props.url}?redirectUrl=${encodeURIComponent(currentUrl)}`);
    } else {
      history.replace(props.url);
    }
  });

  return <div />;
};
