import * as React from 'react';
import { useSelector, useDispatch } from '../redux';
import {
  documentIdSelector,
  documentPublicUriSelector,
  documentIsOwnerSelector,
  documentEditableByOthersSelector,
  documentOtherConnectedClientsSelector,
  documentCurrentLockSelector,
  documentLocksSelector,
  documentClientIdSelector,
  documentEditorStateWithLocksSelector,
  documentCanBeEditedByClientSelector,
} from '../redux/reducers/document';
import { Helmet } from 'react-helmet';
import {
  documentOpened,
  documentClosed,
  documentEditorStateUpdated,
} from '../redux/actions/document';
import { EditorWrapper } from '../components/EditorWrapper';
import { DocumentBody } from '../components/document/DocumentBody';
import { isMissing } from '../lib/typeGuards';
import { Editor, EditorState } from 'draft-js';
import { urlifyTitle, extractTitle } from '../utils/documentTitle';
import history from '../history';
import { ContentStateDiff } from '../../shared/document';
import { buildContentStateDiff } from '../utils/buildContentStateDiff';
import { ShareOpener } from './ShareOpener';
import { ConnectedClients } from './ConnectedClients';
import { LoadingDocument } from './LoadingDocument';
import { useDebounce } from '../hooks';

interface Props {
  documentId: string;
  urlTitle: string;
  isNew: boolean;
}

export const SavedDocument: React.FunctionComponent<Props> = ({ documentId, urlTitle, isNew }) => {
  const documentBodyRef = React.useRef<Editor>(null);
  const openedDocumentId = useSelector(documentIdSelector);
  const clientId = useSelector(documentClientIdSelector);
  const documentPublicUri = useSelector(documentPublicUriSelector);
  const editorState = useSelector(documentEditorStateWithLocksSelector);
  const isDocumentOwner = useSelector(documentIsOwnerSelector);
  const documentCanBeEditedByClient = useSelector(documentCanBeEditedByClientSelector);
  const editableByOthers = useSelector(documentEditableByOthersSelector);
  const currentLock = useSelector(documentCurrentLockSelector);
  const documentLocks = useSelector(documentLocksSelector);
  const connectedClients = useSelector(documentOtherConnectedClientsSelector);
  const dispatch = useDispatch();

  const documentTitle = editorState ? extractTitle(editorState.getCurrentContent()) : null;
  const debouncedTitle = useDebounce(documentTitle, 500);
  const expectedUrlTitle = urlifyTitle(debouncedTitle || '');

  React.useEffect(() => {
    if (openedDocumentId !== documentId) {
      dispatch(documentOpened(documentId));
    }
    return () => {
      dispatch(documentClosed(documentId));
    };
  }, [documentId]);

  React.useEffect(() => {
    if (documentTitle === null || expectedUrlTitle === '') {
      return;
    }

    if (expectedUrlTitle !== urlTitle) {
      history.replace(`/d/${documentPublicUri}/${expectedUrlTitle}`);
    }
  }, [expectedUrlTitle]);

  if (
    isMissing(clientId) ||
    isMissing(editorState) ||
    isMissing(documentTitle) ||
    isMissing(documentPublicUri)
  ) {
    return <LoadingDocument />;
  }

  const editorStateChanged = (newState: EditorState) => {
    let diff: ContentStateDiff | null = null;

    const previousContentState = editorState.getCurrentContent();
    const nextContentState = newState.getCurrentContent();
    if (nextContentState !== previousContentState) {
      diff = buildContentStateDiff(previousContentState, nextContentState);
    }

    let newLock: string | null = currentLock;
    const focusKey = newState.getSelection().getFocusKey();

    if (currentLock && currentLock !== focusKey) {
      newLock = null;
    }

    if (diff && diff.blocks.length > 0 && currentLock !== focusKey) {
      newLock = focusKey;
    }

    if (newLock && documentLocks.has(newLock)) {
      return;
    }

    dispatch(documentEditorStateUpdated(documentId, newState, diff, newLock));
  };

  return (
    <>
      {debouncedTitle ? <Helmet title={`${debouncedTitle} | inline`} /> : null}
      <ShareOpener
        documentId={documentId}
        urlSafeTitle={expectedUrlTitle}
        publicUri={documentPublicUri}
        isDocumentOwner={isDocumentOwner}
        editableByOthers={editableByOthers}
        initiallyOpened={isNew}
      />
      <ConnectedClients connectedClients={connectedClients} />
      <EditorWrapper>
        <DocumentBody
          ref={documentBodyRef}
          clientId={clientId}
          readOnly={!documentCanBeEditedByClient}
          editorKey={documentId}
          editorState={editorState}
          onChange={editorStateChanged}
        />
      </EditorWrapper>
    </>
  );
};
