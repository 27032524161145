import * as React from 'react';
import styled from 'styled-components';

export const Modal = styled.div`
  background-color: ${(props) => props.theme.modal.background};
  color: ${(props) => props.theme.modal.text};
  border-radius: 6px;
  padding: 16px;
  transition: opacity 0.2s linear, margin 0.2s ease-in-out;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 3px 6px rgba(0, 0, 0, 0.15);

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {

  }
`;
