import { deepEqual } from './deepEqual';

export interface Updater<S, C> {
  get: Getter<S, C>;
  set: Setter<S, C>;
}

export type Transformer<C> = (cur: C) => C;
export type Getter<S, C> = <K extends keyof C>(key: K) => Updater<S, C[K]>;
export type Setter<S, C> = (mut: Transformer<C>) => S;

// Hidden type of actual setter which accepts an additional argument
type AwareSetter<S, C> = (mut: Transformer<C>, isLeafUpdater: boolean) => S;

export function update<S extends Object>(state: S): Updater<S, S> {
  function getter<C>(current: C, setter: AwareSetter<S, C>): Getter<S, C> {
    function get<K extends keyof C>(key: K): Updater<S, C[K]> {
      const newCurrent: C[K] = current[key];

      function newSetter(mut: Transformer<C[K]>, isLeafUpdater = false): S {
        // This propagates the object creation up the tree
        return setter((cur: C) => {
          const newVal = mut(newCurrent);
          if (newVal === newCurrent) {
            return cur;
          }
          // If we're the first setter to be called, do a more expensive
          // equality check to see if we can avoid updating the state
          if (!isLeafUpdater && deepEqual(newVal, newCurrent)) {
            return cur;
          }
          return {
            ...(cur as any),
            [key as any]: newVal,
          };
        }, true); // Pass true to nested setter
      }
      return {
        get: getter(newCurrent, newSetter),
        set: newSetter,
      };
    }
    return get;
  }
  const initialSetter = (mutator: Transformer<S>, isLeafUpdater = false) => mutator(state);

  return {
    get: getter(state, initialSetter),
    set: initialSetter,
  };
}
/*
 * Functions to aid in updating Objects
 */

// Transforms a partial update into a full one by copying elements from the previous value
// This lets you update multiple parts of a structure without needing to use the spread syntax
export function partial<T extends object>(func: (val: T) => Partial<T>): (val: T) => T {
  return (val) => {
    return {
      ...(val as any),
      ...(func(val) as any),
    };
  };
}
