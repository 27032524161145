import * as React from 'react';
import styled from 'styled-components';
import {
  EditorState,
  RichUtils,
  DraftHandleValue,
  ContentBlock,
  getDefaultKeyBinding,
  Editor,
} from 'draft-js';
import { InlineEditorCommand } from '../../draft/utils';
import { DocumentBlockType } from '../../../shared/document';
import { handleReturn } from '../../draft/handlers/handleReturn';
import { handleBeforeInput } from '../../draft/handlers/handleBeforeInput';
import { WrappedEditorBlock } from './WrappedEditorBlock';

const DocumentBodyStyled = styled.div<{ metaPressed: boolean }>`
  line-height: 24px;
  min-height: 50vh;
  cursor: text;

  .DraftEditor-editorContainer {
    background-color: rgba(255, 255, 255, 0);
    border-left: 0.1px solid transparent;
    position: relative;
    z-index: 1;
  }

  .public-DraftEditorPlaceholder-root {
    font-weight: 400;
    color: #9197a3;
    position: absolute;
    z-index: 1;
  }

  .public-DraftEditorPlaceholder-hasFocus {
    color: #bdc1c9;
  }

  .block--${DocumentBlockType.UNSTYLED}, .block-header,
  ul,
  ol {
    margin-bottom: 12px;
    position: relative;
  }

  ol {
    counter-reset: o-list;
  }

  .block--${DocumentBlockType.UNORDERED_LIST_ITEM}, .block--${DocumentBlockType.ORDERED_LIST_ITEM} {
    position: relative;
    padding-left: 1.3em;

    &.list-depth--1 {
      padding-left: 2.6em;
    }

    &.list-depth--2 {
      padding-left: 3.9em;
    }

    &.list-depth--3 {
      padding-left: 5.2em;
    }

    &.list-depth--4 {
      padding-left: 6.5em;
    }

    &:before {
      position: absolute;
      margin-left: -1.3em; /* same as padding-left set on li */
      width: 1.3em; /* same as padding-left set on li */
    }
  }

  .block--${DocumentBlockType.ORDERED_LIST_ITEM} {
    counter-increment: o-list;

    &:before {
      content: counter(o-list) '.';
    }
  }

  .block--${DocumentBlockType.UNORDERED_LIST_ITEM}:before {
    content: '-';
  }

  .block-header {
    position: relative;
    font-weight: 700;

    &:before {
      content: '#';
      position: absolute;
      top: 0;
      left: -39px;
      font-weight: 600;
      text-align: right;
      display: block;
      width: 30px;
    }
  }

  .block--${DocumentBlockType.HEADER_TWO}:before {
    content: '##';
  }

  .block--${DocumentBlockType.HEADER_THREE}:before {
    content: '###';
  }

  a {
    color: inherit;

    ${(props) =>
      props.metaPressed &&
      `
      cursor: pointer;
    `}
  }

  .public-DraftStyleDefault-block {
    transition: background-color 0.1s linear;
  }
`;

interface Props {
  clientId?: string;
  readOnly?: boolean;
  editorState: EditorState;
  editorKey: string;
  onChange: (state: EditorState) => void;
}

export const DocumentBody = React.forwardRef<Editor, Props>((props, ref) => {
  const [metaPressed, setMetaPressed] = React.useState(false);
  const editorState = props.editorState;

  React.useEffect(() => {
    props.onChange(EditorState.moveFocusToEnd(editorState));
  }, [props.editorKey]);

  React.useEffect(() => {
    const keyUpdate = (e: KeyboardEvent) => {
      setMetaPressed(e.metaKey);
    };
    window.addEventListener('keydown', keyUpdate);
    window.addEventListener('keyup', keyUpdate);

    return () => {
      window.removeEventListener('keydown', keyUpdate);
      window.removeEventListener('keyup', keyUpdate);
    };
  });

  const myBlockStyleFn = (contentBlock: ContentBlock): string => {
    const type = contentBlock.getType() as DocumentBlockType;
    switch (type) {
      case DocumentBlockType.HEADER_ONE:
      case DocumentBlockType.HEADER_TWO:
      case DocumentBlockType.HEADER_THREE:
        return `block--${type} block-header`;
      case DocumentBlockType.UNORDERED_LIST_ITEM:
      case DocumentBlockType.ORDERED_LIST_ITEM:
        return `block--${type} list-depth--${contentBlock.getDepth()}`;
      default:
        return `block--${type}`;
    }
  };

  const keyBindingFn = (e: React.KeyboardEvent): InlineEditorCommand | null => {
    if (e.keyCode === 9) {
      // Tab
      e.preventDefault();
      const newState = RichUtils.onTab(e, editorState, 4);
      if (newState) {
        props.onChange(newState);
        return 'indent-line';
      }
    }

    return getDefaultKeyBinding(e);
  };

  const handleKeyCommand = (command: InlineEditorCommand, state: EditorState): DraftHandleValue => {
    const newState = RichUtils.handleKeyCommand(state, command);
    if (newState) {
      props.onChange(newState);
      return 'handled';
    }

    return 'not-handled';
  };

  const blockRendererFn = (contentBlock: ContentBlock) => {
    const data = contentBlock.getData();
    return {
      component: WrappedEditorBlock,
      editable: !data.get('lock', null) && !data.get('readOnly', false),
    };
  };

  return (
    <DocumentBodyStyled metaPressed={metaPressed}>
      <Editor
        ref={ref}
        placeholder="What's on your mind?"
        readOnly={props.readOnly}
        editorKey={props.editorKey}
        editorState={editorState}
        onChange={props.onChange}
        keyBindingFn={keyBindingFn}
        blockStyleFn={myBlockStyleFn}
        blockRendererFn={blockRendererFn}
        handleKeyCommand={handleKeyCommand}
        handleBeforeInput={(char, es) => handleBeforeInput(char, es, props.onChange)}
        handleReturn={(e: React.KeyboardEvent, es) => handleReturn(e, es, props.onChange)}
      />
    </DocumentBodyStyled>
  );
});
