import * as React from 'react';
import { useTransition, animated } from 'react-spring';
import styled from 'styled-components';
import { Modal } from './Modal';
import { ShareOpenerModal } from './ShareOpener/ShareOpenerModal';

const ShareOpenerContainer = styled.div<{ ref: any }>`
  position: fixed;
  top: 32px;
  right: 32px;
  z-index: 10;

  ${Modal} {
    position: absolute;
    top: 8px;
    right: 0;
    width: 430px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    top: 16px;
    right: 16px;

    ${Modal} {
      top: 12px;
      width: calc(100vw - 32px);
    }
  }
`;

const ShareOpenerButton = styled.button`
  cursor: pointer;
  outline: none;
  color: ${(props) => props.theme.shareOpener.text};
  background-color: ${(props) => props.theme.shareOpener.default};
  border: 1px solid ${(props) => props.theme.shareOpener.border};
  outline: none;
  border-radius: 8px;
  padding: 7px 16px;
  font-size: 14px;
  font-weight: 500;
  transition: background-color 0.2s linear, border-color 0.2s linear;

  &:hover {
    background-color: ${(props) => props.theme.shareOpener.hover};
  }
`;

interface Props {
  documentId: string;
  publicUri: string;
  urlSafeTitle: string;
  isDocumentOwner: boolean;
  editableByOthers: boolean;
  initiallyOpened: boolean;
}

export const ShareOpener: React.FunctionComponent<Props> = (props) => {
  const openerRef = React.useRef<HTMLDivElement>();
  const [active, setActive] = React.useState(props.initiallyOpened);
  const transitions = useTransition(active, null, {
    config: {
      tension: 200,
    },
    from: { opacity: 0, transform: 'translate3d(0,15px,0)' },
    enter: { opacity: 1, transform: 'translate3d(0,0,0)' },
    leave: { opacity: 0, transform: 'translate3d(0,15px,0)' },
  });

  const handleClick = (e: MouseEvent) => {
    if (openerRef.current?.contains(e.target as any)) {
      return;
    }

    setActive(false);
  };

  React.useEffect(() => {
    if (active) {
      document.addEventListener('mousedown', handleClick);
    } else {
      document.removeEventListener('mousedown', handleClick);
    }
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [active]);

  return (
    <ShareOpenerContainer ref={openerRef}>
      <ShareOpenerButton onClick={() => setActive(!active)}>SHARE</ShareOpenerButton>
      {transitions.map(
        ({ item, key, props: style }) =>
          item && (
            <animated.div key={key} style={style}>
              <Modal>
                <ShareOpenerModal {...props} />
              </Modal>
            </animated.div>
          ),
      )}
    </ShareOpenerContainer>
  );
};
