import { Middleware } from '..';
import {
  documentIsOpenSelector,
  documentReconnectAttemptsSelector,
  documentSocketStateSelector,
  documentIsOpenAndConnectedSelector,
  documentEditorStateSelector,
} from '../reducers/document';
import {
  isDocumentAction,
  documentSocketStateChanged,
  documentSocketSubscribed,
  documentClientConnected,
  documentClientDisconnected,
  documentReconnectAttemptFailed,
  documentClientSelectionUpdated,
  documentClientContentChange,
  documentClientPermissionsChanged,
  DocumentEditorStateUpdated,
} from '../actions/document';
import { notMissing, isMissing } from '../../lib/typeGuards';
import { SocketState } from '../../socket/SocketState';
import {
  SocketClientMessage,
  SubscribedMessage,
  SocketServerMessage,
} from '../../../shared/socket/messages';
import { ConnectedSocketClientSelection } from '../../../shared/socket/client';
import { socketMessageHandler } from '../../socket/utils';
import { RECOVERABLE_CLOSE_CODES } from '../../../shared/socket/lifecycle';
import { EditorState, ContentState } from 'draft-js';
import { buildShareableSelection, InlineEditorCommand } from '../../draft/utils';
import { buildContentStateDiff } from '../../utils/buildContentStateDiff';
import { authenticationTokenSelector } from '../reducers/authentication';

const BASE_WS_URL = process.env.WS_URL!;
const RECONNECT_ATTEMPTS_BACKOFF_MS = [500, 1000, 3000, 5000, 10000];

export const documentSocketMiddleware: Middleware = ({ dispatch, getState }) => {
  let socket: WebSocket | null = null;
  let reconnectTimeout: number | null = null;

  const authToken = () => authenticationTokenSelector(getState());
  const hasOpenDocument = () => documentIsOpenSelector(getState());
  const socketState = () => documentSocketStateSelector(getState());
  const hasOpenAndConnectedDocument = () => documentIsOpenAndConnectedSelector(getState());
  const documentEditorState = () => documentEditorStateSelector(getState());
  const reconnectAttempts = () => documentReconnectAttemptsSelector(getState());

  const updateSocketState = (d: string, s: SocketState) =>
    dispatch(documentSocketStateChanged(d, s));

  function createSocket(documentId: string): void {
    const socketUrl = `${BASE_WS_URL}/documents/${documentId}/live`;
    try {
      socket = new WebSocket(socketUrl);
      socket.onopen = () => {
        updateSocketState(documentId, SocketState.SUBSCRIBING);
        sendMessageToSocket({
          type: 'SUBSCRIBE',
          documentId: documentId,
          token: authToken(),
        });
      };
      socket.onmessage = socketMessageHandler(
        (message) => {
          if (message.type === 'SUBSCRIBED') {
            socketConnected(documentId, message);
          }
        },
        (data) => {
          console.log(`Failed to parse socket message`);
          console.log(data);
        },
      );
      socket.onclose = (ev: WebSocketCloseEvent) => {
        console.log(`Socket connection closed during setup. code=${ev.code} reason=${ev.reason}`);
        socketFailedSetup(documentId);
      };
    } catch (e) {
      console.log(`Failed to open socket connection: ${socketUrl}`);
      console.log(e);
      socketFailedSetup(documentId);
    }
  }

  function cleanupSocket(): void {
    if (notMissing(reconnectTimeout)) {
      clearTimeout(reconnectTimeout);
    }

    socket?.close(1000);
    if (socket) {
      socket.onopen = () => {};
      socket.onmessage = () => {};
      socket.onclose = () => {};
    }
    socket = null;
  }

  function socketConnected(documentId: string, subscribed: SubscribedMessage): void {
    console.log(`Client connected. Document: ${documentId}. Client: ${subscribed.auth.clientId}`);
    dispatch(documentSocketSubscribed(documentId, subscribed));
    updateSocketState(documentId, SocketState.CONNECTED);
    socket!.onmessage = socketMessageHandler(
      (message) => onMessage(documentId, message),
      (data) => {
        console.log(`Failed to parse socket message`);
        console.log(data);
      },
    );
    socket!.onclose = (ev) => onClose(documentId, ev);
  }

  function socketFailedSetup(documentId: string): void {
    const backoff = RECONNECT_ATTEMPTS_BACKOFF_MS[reconnectAttempts()];
    dispatch(documentReconnectAttemptFailed(documentId));
    const isAttempingReconnect = socketState() === SocketState.RECONNECTING;

    if (!isAttempingReconnect || isMissing(backoff)) {
      updateSocketState(documentId, SocketState.FAILED);
      if (isAttempingReconnect) {
        console.log(`Reconnect failed. All retries exhausted`);
      }
      return;
    }

    console.log(`Reconnect failed, retry in ${backoff}ms`);
    reconnectTimeout = setTimeout(() => {
      reconnectTimeout = null;
      createSocket(documentId);
    }, backoff);
  }

  function onMessage(documentId: string, message: SocketServerMessage): void {
    switch (message.type) {
      case 'CLIENT_CONNECTED':
        dispatch(documentClientConnected(documentId, message.client));
        break;
      case 'CLIENT_DISCONNECTED':
        dispatch(documentClientDisconnected(documentId, message.clientId));
        break;
      case 'SELECTION_UPDATED':
        dispatch(documentClientSelectionUpdated(documentId, message));
        break;
      case 'CONTENT_UPDATED':
        dispatch(documentClientContentChange(documentId, message));
        break;
      case 'PERMISSIONS_CHANGED':
        dispatch(documentClientPermissionsChanged(documentId, message));
        break;
      default:
    }
  }

  function onClose(documentId: string, ev: WebSocketCloseEvent): void {
    console.log(`Socket closed for document ${documentId} code=${ev.code} reason=${ev.reason}`);
    if (notMissing(ev.code) && RECOVERABLE_CLOSE_CODES.includes(ev.code)) {
      updateSocketState(documentId, SocketState.RECONNECTING);
      createSocket(documentId);
    } else {
      updateSocketState(documentId, SocketState.DISCONNECTED);
    }
  }

  function sendMessageToSocket(message: SocketClientMessage): void {
    socket?.send(JSON.stringify(message));
  }

  let previousSelection: ConnectedSocketClientSelection | null;
  function maybeShareSelection(
    documentId: string,
    editorState: EditorState,
    newLock: string | null,
  ): void {
    const selection = buildShareableSelection(editorState.getSelection());
    if (
      (previousSelection === null && selection !== null) ||
      (previousSelection !== null && selection === null) ||
      (selection &&
        previousSelection &&
        (selection.anchorKey !== previousSelection.anchorKey ||
          selection.anchorOffset !== previousSelection.anchorOffset ||
          selection.focusKey !== previousSelection.focusKey ||
          selection.focusOffset !== previousSelection.focusOffset))
    ) {
      sendMessageToSocket({
        type: 'SELECTION_UPDATED',
        documentId,
        selection,
        newLock,
      });
      previousSelection = selection;
    }
  }

  function shareContentStateUpdate(documentId: string, action: DocumentEditorStateUpdated): void {
    if (action.diff === null) {
      return maybeShareSelection(documentId, action.editorState, action.newLock);
    }

    sendMessageToSocket({
      type: 'CONTENT_UPDATED',
      documentId,
      ...action.diff,
      selection: buildShareableSelection(action.editorState.getSelection()),
      newLock: action.newLock,
    });
  }

  return (next) => (action) => {
    if (!isDocumentAction(action)) {
      return next(action);
    }

    if (!hasOpenAndConnectedDocument()) {
      switch (action.type) {
        case 'DOCUMENT_OPENED':
          if (hasOpenDocument()) {
            console.error('DOCUMENT_OPENED dispatched before closing existing Document');
            console.log(action);
            return next(action);
          }

          const docOpenResult = next(action);
          if (notMissing(socket)) {
            cleanupSocket();
          }
          updateSocketState(action.documentId, SocketState.CONNECTING);
          createSocket(action.documentId);
          return docOpenResult;
        default:
          return next(action);
      }
    }

    const result = next(action);

    switch (result.type) {
      case 'DOCUMENT_CLOSED':
        cleanupSocket();
        break;
      case 'DOCUMENT_EDITOR_STATE_UPDATED':
        shareContentStateUpdate(result.documentId, result);
        break;
      case 'DOCUMENT_PERMISSIONS_CHANGED':
        sendMessageToSocket({
          type: 'PERMISSIONS_CHANGED',
          documentId: action.documentId,
          isOpen: result.isOpen,
        });
        break;
      default:
    }

    return result;
  };
};
