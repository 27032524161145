import { Location } from 'history';
import { Path, TestMatch } from 'path-parser';
import { AppAction, AppState } from '../';
import { notMissing } from '../../lib/typeGuards';

export enum Route {
  NewDocument,
  SavedDocument,
  NotFound,
}

export type RouterState = NewDocumentRoute | SavedDocumentRoute | NotFoundRoute;

interface BaseRoute<R extends Route> {
  route: R;
  rawPath: string;
}

type NewDocumentRoute = BaseRoute<Route.NewDocument>;

interface SavedDocumentRoute extends BaseRoute<Route.SavedDocument> {
  documentUri: string;
  urlTitle: string;
}

type NotFoundRoute = BaseRoute<Route.NotFound>;

export const initialRouterState = parseLocation;

export function routerReducer(state: RouterState, action: AppAction): RouterState {
  switch (action.type) {
    case 'ROUTER_URL_UPDATED':
      return parseLocation(action.location);
    default:
      return state;
  }
}

export const routerSelector = (state: AppState) => state.router;
export const rawPathSelector = (state: AppState) => state.router.rawPath;

function parseLocation(location: Location): RouterState {
  switch (location.pathname) {
    case '/':
      return {
        route: Route.NewDocument,
        rawPath: location.pathname,
      };
    default:
  }

  const savedDocumentPathWithTitle = new Path(`/d/:documentUri/:urlTitle`).test(location.pathname);
  if (documentUriPathIsMatch(savedDocumentPathWithTitle)) {
    return {
      route: Route.SavedDocument,
      rawPath: location.pathname,
      documentUri: savedDocumentPathWithTitle.documentUri,
      urlTitle: savedDocumentPathWithTitle.urlTitle || '',
    };
  }

  const savedDocumentPath = new Path(`/d/:documentUri`).test(location.pathname);
  if (documentUriPathIsMatch(savedDocumentPath)) {
    return {
      route: Route.SavedDocument,
      rawPath: location.pathname,
      documentUri: savedDocumentPath.documentUri,
      urlTitle: savedDocumentPath.urlTitle || '',
    };
  }

  return {
    route: Route.NotFound,
    rawPath: location.pathname,
  };
}

type PathWithDocumentUri = { documentUri: string; urlTitle: string };
function documentUriPathIsMatch(matchTest: TestMatch): matchTest is PathWithDocumentUri {
  return notMissing(matchTest?.documentUri);
}
