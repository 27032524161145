import '@babel/polyfill';
import './scss/index.scss';
// import 'draft-js/dist/Draft.css';

import * as React from 'react';
import { enableMapSet } from 'immer';
import { Reducer } from 'redux';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, compose, applyMiddleware } from 'redux';
import { App } from './components/App';
import history from './history';
import { appReducer, appInitialState, AppStore } from './redux';
import { documentSocketMiddleware } from './redux/middlewares/documentSocketMiddleware';
import { appSettingsThemeChanged } from './redux/actions/appSettings';
import { ThemeName } from './themes/ThemeName';
import { routerUrlUpdated } from './redux/actions/router';

enableMapSet();

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store: AppStore = createStore(
  appReducer() as Reducer,
  appInitialState(history.location),
  composeEnhancers(applyMiddleware(documentSocketMiddleware)),
);

if (window.matchMedia) {
  window.matchMedia('(prefers-color-scheme: dark)').addListener(() => {
    const theme = window.matchMedia('(prefers-color-scheme: dark)').matches
      ? ThemeName.DARK
      : ThemeName.LIGHT;
    store.dispatch(appSettingsThemeChanged(theme));
  });
}

history.listen((location) => store.dispatch(routerUrlUpdated(location)));

render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);

console.log(`useinline [${process.env.COMMIT_REF}]`);
