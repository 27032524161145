import { DefaultTheme } from 'styled-components';
import { ThemeName } from './ThemeName';
import { lighten, darken } from 'polished';

export const darkTheme: DefaultTheme = {
  name: ThemeName.DARK,

  breakpoints: {
    narrow: 1150,
    tablet: 800,
    mobile: 425,
  },

  background: '#222',
  primary: '#5A71D8',

  typography: {
    default: '#FFF',
    placeholder: '#E0E0E0',
  },

  modal: {
    background: '#111',
    text: '#FFF',
    iconBackground: '#545464',
  },

  tooltip: {
    background: '#111',
    text: '#FFF',
  },

  settingsOpener: {
    background: '#111',
    hover: darken(0.06)('#111'),
    border: '#111',
    text: '#5A71D8',
  },

  shareOpener: {
    default: '#111',
    hover: darken(0.06)('#111'),
    border: '#111',
    text: '#5A71D8',
  },

  shareModal: {
    urlBackground: '#242424',
    urlText: '#222',
    urlCopy: '#5A71D8',
    urlCopySuccess: '#5EBB3B',
    settingIconBubble: '#242424',
    settingIcon: '#5F666F',
    settingText: '#E0E0E0',
  },

  primaryButton: {
    default: '#5A71D8',
    hover: lighten(0.05)('#5A71D8'),
    disabled: lighten(0.06)('#5A71D8'),
    text: '#FFF',
  },

  toggle: {
    track: '#545464',
    handle: '#FFF',
    active: '#5A71D8',
  },
};
