import { Store } from 'redux';
import {
  useSelector as untypedUseSelector,
  useStore as untypedUseStore,
  useDispatch as untypedUseDispatch,
  TypedUseSelectorHook,
} from 'react-redux';
import { Location } from 'history';
import { documentReducer, DocumentState, initialDocumentState } from './reducers/document';
import { Reducer } from 'react';
import { DocumentAction } from './actions/document';
import { AppSettingsAction } from './actions/appSettings';
import {
  AppSettingsState,
  initialAppSettingsState,
  appSettingsReducer,
} from './reducers/appSettings';
import { RouterState, initialRouterState, routerReducer } from './reducers/router';
import { RouterAction } from './actions/router';
import { NewDocumentAction } from './actions/newDocument';
import {
  initialNewDocumentState,
  newDocumentReducer,
  NewDocumentState,
} from './reducers/newDocument';
import {
  AuthenticationState,
  initialAuthenticationState,
  authenticationReducer,
} from './reducers/authentication';
import { AuthenticationAction } from './actions/authentication';
import {
  UriResolutionsState,
  initialUriResolutionsState,
  uriResolutionsReducer,
} from './reducers/uriResolutions';
import { UriResolutionsAction } from './actions/uriResolutions';

export interface BaseAction<T extends string> {
  type: T;
}

export type AppAction =
  | AuthenticationAction
  | AppSettingsAction
  | NewDocumentAction
  | DocumentAction
  | RouterAction
  | UriResolutionsAction;

export interface AppState {
  authentication: AuthenticationState;
  appSettings: AppSettingsState;
  document: DocumentState;
  newDocument: NewDocumentState;
  router: RouterState;
  uriResolutions: UriResolutionsState;
}

export type AppStore = Store<AppState, AppAction>;

export type DispatchFunction = (action: AppAction) => AppAction;

interface MiddlewareAPI {
  dispatch: DispatchFunction;
  getState(): AppState;
}

export interface Middleware {
  (api: MiddlewareAPI): (next: DispatchFunction) => (action: AppAction) => AppAction;
}

export function appInitialState(location: Location): AppState {
  return {
    authentication: initialAuthenticationState(),
    appSettings: initialAppSettingsState(),
    document: initialDocumentState(),
    newDocument: initialNewDocumentState(),
    router: initialRouterState(location),
    uriResolutions: initialUriResolutionsState(),
  };
}

export function appReducer(): Reducer<AppState, AppAction> {
  return (state: Required<AppState>, action) => ({
    authentication: authenticationReducer(state.authentication, action),
    appSettings: appSettingsReducer(state.appSettings, action),
    document: documentReducer(state.document, action),
    newDocument: newDocumentReducer(state.newDocument, action),
    router: routerReducer(state.router, action),
    uriResolutions: uriResolutionsReducer(state.uriResolutions, action),
  });
}

export const useSelector: TypedUseSelectorHook<AppState> = untypedUseSelector;
export const useStore: () => AppStore = untypedUseStore;
export const useDispatch: () => DispatchFunction = untypedUseDispatch;
