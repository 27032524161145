import { ContentState } from 'draft-js';

export function extractTitle(cs: ContentState): string {
  const firstBlockText = cs.getFirstBlock().getText();
  if (firstBlockText.length === 0) {
    return 'Untitled';
  }
  return firstBlockText;
}

export function urlifyTitle(title: string): string {
  return (title || '').replace(/\W+/g, '-').toLowerCase().slice(0, 45);
}
