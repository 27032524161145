import * as React from 'react';
import styled from 'styled-components';
import { PrivacyIcon } from '../icons/PrivacyIcon';
import { ToggleInput } from '../ToggleInput';
import { useDispatch, useSelector } from '../../redux';
import { appSettingsThemeSelector } from '../../redux/reducers/appSettings';
import { appSettingsThemeChanged } from '../../redux/actions/appSettings';
import { ThemeName } from '../../themes/ThemeName';

const SettingsOpenerModalStyled = styled.div`
  width: 300px;
`;

const Title = styled.h2`
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 16px;
`;

const SettingToggle = styled.div`
  display: flex;
  margin-bottom: 16px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const SettingIconBubble = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  width: 36px;
  height: 36px;
  margin-right: 12px;
  background: ${(props) => props.theme.shareModal.settingIconBubble};

  svg {
    fill: ${(props) => props.theme.shareModal.settingIcon};
  }
`;

const SettingText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  line-height: 16px;
  color: ${(props) => props.theme.shareModal.settingText};
`;

const SettingSwitch = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SettingSpacer = styled.div`
  flex-grow: 1;
`;

interface Props {}

export const SettingsOpenerModal: React.FunctionComponent<Props> = (props) => {
  const theme = useSelector(appSettingsThemeSelector);
  const dispatch = useDispatch();
  const [unlocked, setUnlocked] = React.useState(false);

  const isDarkMode = theme === ThemeName.DARK;

  const toggleDarkMode = () => {
    dispatch(appSettingsThemeChanged(isDarkMode ? ThemeName.LIGHT : ThemeName.DARK));
  };

  return (
    <SettingsOpenerModalStyled>
      <Title>Settings</Title>
      {/* <SettingToggle>
        <SettingIconBubble>
          <PrivacyIcon />
        </SettingIconBubble>
        <SettingText>Counter and read time</SettingText>
        <SettingSpacer />
        <SettingSwitch>
          <ToggleInput value={unlocked} onChange={setUnlocked} />
        </SettingSwitch>
      </SettingToggle> */}
      <SettingToggle>
        <SettingIconBubble>
          <PrivacyIcon />
        </SettingIconBubble>
        <SettingText>Dark mode</SettingText>
        <SettingSpacer />
        <SettingSwitch>
          <ToggleInput value={isDarkMode} onChange={toggleDarkMode} />
        </SettingSwitch>
      </SettingToggle>
      {/* <SettingToggle>
        <SettingIconBubble>
          <PrivacyIcon />
        </SettingIconBubble>
        <SettingText>Focus mode</SettingText>
        <SettingSpacer />
        <SettingSwitch>
          <ToggleInput value={unlocked} onChange={setUnlocked} />
        </SettingSwitch>
      </SettingToggle> */}
    </SettingsOpenerModalStyled>
  );
};
