import { EditorState, Modifier, ContentBlock, ContentState } from 'draft-js';

export function splitBlockAndChange(editorState: EditorState, type = 'unstyled'): EditorState {
  const currentSelection = editorState.getSelection();
  const currentContent = Modifier.splitBlock(editorState.getCurrentContent(), currentSelection);
  const selection = currentContent.getSelectionAfter();
  const key = selection.getStartKey();
  const blockMap = currentContent.getBlockMap();
  const block = blockMap.get(key);
  const newBlock = block.merge({ type }) as ContentBlock;
  const newContentState = currentContent.merge({
    blockMap: blockMap.set(key, newBlock),
    selectionAfter: selection,
  }) as ContentState;

  return EditorState.push(editorState, newContentState, 'split-block');
}
