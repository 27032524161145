import * as React from 'react';
import { useSelector, useDispatch } from '../redux';
import { uriResolutionSelectorCreator, ResolutionStatus } from '../redux/reducers/uriResolutions';
import {
  uriResolutionStarted,
  uriResolutionResolved,
  uriResolutionFailed,
} from '../redux/actions/uriResolutions';
import { apiClient } from '../api/InlineAPIClient';
import { SettingsOpener } from '../components/SettingsOpener';
import { PrimaryLogo } from '../components/PrimaryLogo';
import { SavedDocument } from '../components/SavedDocument';
import { FailedToLoadDocument } from '../components/FailedToLoadDocument';
import { LoadingDocument } from '../components/LoadingDocument';
import { Helmet } from 'react-helmet';

interface Props {
  documentUri: string;
  urlTitle: string;
}

export const SavedDocumentPage: React.FunctionComponent<Props> = ({ documentUri, urlTitle }) => {
  const dispatch = useDispatch();
  const uriResolution = useSelector(uriResolutionSelectorCreator(documentUri));

  React.useLayoutEffect(() => {
    if (uriResolution !== undefined) {
      return;
    }

    dispatch(uriResolutionStarted(documentUri));

    apiClient
      .resolveDocumentId(documentUri)
      .then(({ documentId }) => dispatch(uriResolutionResolved(documentUri, documentId)))
      .catch(() => dispatch(uriResolutionFailed(documentUri)));
  }, [uriResolution]);

  let pageElement: JSX.Element;

  if (!uriResolution || uriResolution.status === ResolutionStatus.RESOLVING) {
    pageElement = <LoadingDocument key='loading' />;
  } else if (uriResolution.status === ResolutionStatus.FAILED) {
    pageElement = <FailedToLoadDocument key='failed-to-load' />;
  } else {
    pageElement = (
      <SavedDocument
        documentId={uriResolution.documentId}
        urlTitle={urlTitle}
        isNew={uriResolution.new}
      />
    );
  }

  return (
    <>
      <Helmet title='inline' />
      <PrimaryLogo />
      <SettingsOpener />
      {pageElement}
    </>
  );
};
