import * as React from 'react';
import styled from 'styled-components';

const EditorContainer = styled.div`
  margin: 192px auto 50px;
  width: calc(100% - 80px);
  max-width: 640px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    margin: 125px auto 50px;
    width: calc(100% - 60px);
  }
`;

export const EditorWrapper: React.FunctionComponent = (props) => {
  return <EditorContainer>{props.children}</EditorContainer>;
};
