import { ContentState, RawDraftContentBlock, convertToRaw } from 'draft-js';
import {
  DocumentBlock,
  ContentStateDiff,
  DocumentLayoutDiff,
  DocumentBlockType,
  BlockEntity,
} from '../../shared/document';
import { Dictionary } from '../lib/Dictionary';

type RawBlockMap = Map<string, RawDraftContentBlock>;

export function buildContentStateDiff(
  prev: ContentState,
  curr: ContentState,
): ContentStateDiff | null {
  const layout = diffDocumentLayout(prev, curr);
  const modifiedBlockKeys = diffBlockContent(prev, curr);

  if (modifiedBlockKeys.length === 0 && !layout) {
    return null;
  }

  const rawBlockMap = buildRawBlockMap(curr);
  return {
    layout,
    blocks: fillModifiedBlocks(modifiedBlockKeys, rawBlockMap, curr),
  };
}

export function buildRawBlockMap(contentState: ContentState): Map<string, RawDraftContentBlock> {
  const raw = convertToRaw(contentState);

  return new Map(raw.blocks.map((block) => [block.key, block]));
}

export function diffDocumentLayout(
  prev: ContentState,
  curr: ContentState,
): DocumentLayoutDiff | null {
  const prevBlockMap = prev.getBlockMap();
  const currBlockMap = curr.getBlockMap();
  if (prevBlockMap.size === currBlockMap.size) {
    return null;
  }

  const prevKeys = prevBlockMap.keySeq().toSet();
  const currKeys = currBlockMap.keySeq().toSet();

  const removed = prevKeys.subtract(currKeys).toArray();
  const added = currKeys
    .subtract(prevKeys)
    .toArray()
    .map((key): [string | null, string] => [curr.getKeyBefore(key), key]);

  return {
    added,
    removed,
  };
}

export function fillModifiedBlocks(
  keys: string[],
  rawBlockMap: RawBlockMap,
  contentState: ContentState,
): DocumentBlock[] {
  const blockMap = contentState.getBlockMap();
  const entityMap = contentState.getEntityMap();

  return keys.map((key) => {
    const { type, depth, text, inlineStyleRanges, entityRanges, data } = rawBlockMap.get(key)!;
    const rich = blockMap.get(key);
    const uuidEntityMap: Dictionary<BlockEntity<any>> = {};
    const uuidEntityRanges = entityRanges.map(({ offset, length }) => {
      const entity = rich.getEntityAt(offset);
      uuidEntityMap[entity] = entityMap.get(entity);
      return {
        key: entity,
        offset,
        length,
      };
    });
    return {
      key,
      type: type as DocumentBlockType,
      depth,
      text,
      inlineStyleRanges,
      entityRanges: uuidEntityRanges,
      entityMap: uuidEntityMap,
      data,
    };
  });
}

export function diffBlockContent(prev: ContentState, curr: ContentState): string[] {
  const modifiedKeys: string[] = [];
  for (const block of curr.getBlocksAsArray()) {
    const key = block.getKey();
    if (block !== prev.getBlockForKey(key)) {
      modifiedKeys.push(key);
    }
  }

  return modifiedKeys;
}
