export enum InlineSocketCloseCode {
  NormalClosure = 1000,
  AbnormalClosure = 1006,
  ServerRestart = 1012,

  // 4900-4915: Authentication / Subscribing
  InvalidUrlStructure = 4900,
  SubscribeMessageTimeout = 4901,
  NonSubscribeMessageReceived = 4902,
  MalformedSubscribeReceived = 4903,
  SubscribeDocumentMismatch = 4904,
  FailedAuthForDocument = 4905,
  DocumentNotFound = 4906,
  MultipleWindowsOpenForDocument = 4910,
}

export const RECOVERABLE_CLOSE_CODES = [
  InlineSocketCloseCode.AbnormalClosure,
  InlineSocketCloseCode.ServerRestart,
];
