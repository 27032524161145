import { EditorState } from 'draft-js';
import { makeList } from './makeList';

export function handleList(editorState: EditorState, character: string): EditorState {
  if (character !== ' ') {
    return editorState;
  }

  const selectionState = editorState.getSelection();
  if (selectionState.getStartOffset() > 2) {
    return editorState;
  }

  const key = selectionState.getStartKey();
  const text = editorState.getCurrentContent().getBlockForKey(key).getText();

  if (['-', '*', '+'].includes(text[0])) {
    return makeList(editorState, false);
  }

  if (text === '1.') {
    return makeList(editorState, true);
  }

  return editorState;
}
