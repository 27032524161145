import * as React from 'react';
import { useTransition, animated } from 'react-spring';
import styled from 'styled-components';
import { Modal } from './Modal';
import { SettingsCogIcon } from './icons/SettingsCogIcon';
import { SettingsOpenerModal } from './SettingsOpener/SettingsOpenerModal';

const SettingsOpenerContainer = styled.div<{ ref: any }>`
  position: fixed;
  bottom: 32px;
  right: 32px;
  z-index: 10;

  ${Modal} {
    position: absolute;
    bottom: 45px;
    right: 0;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    top: 16px;
    right: 103px;
    bottom: unset;

    ${Modal} {
      width: calc(100vw - 32px);
      top: 12px;
      right: -87px;
      bottom: unset;
    }
  }
`;

const SettingsOpenerButton = styled.button`
  cursor: pointer;
  z-index: 10;
  outline: none;
  background-color: ${(props) => props.theme.settingsOpener.background};
  border: 1px solid ${(props) => props.theme.settingsOpener.border};
  height: 36px;
  width: 36px;
  outline: none;
  border-radius: 100%;
  padding: 0;
  font-size: 16px;
  transition: background-color 0.2s linear, border-color 0.2s linear;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: ${(props) => props.theme.settingsOpener.text};
  }

  &:hover {
    background-color: ${(props) => props.theme.settingsOpener.hover};
  }
`;

interface Props {}

export const SettingsOpener: React.FunctionComponent<Props> = (props) => {
  const openerRef = React.useRef<HTMLDivElement>();
  const [active, setActive] = React.useState(false);
  const transitions = useTransition(active, null, {
    config: {
      tension: 200,
    },
    from: { opacity: 0, transform: 'translate3d(0,-15px,0)' },
    enter: { opacity: 1, transform: 'translate3d(0,0,0)' },
    leave: { opacity: 0, transform: 'translate3d(0,-15px,0)' },
  });

  const handleClick = (e: MouseEvent) => {
    if (openerRef.current?.contains(e.target as any)) {
      return;
    }

    setActive(false);
  };

  React.useEffect(() => {
    if (active) {
      document.addEventListener('mousedown', handleClick);
    } else {
      document.removeEventListener('mousedown', handleClick);
    }
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [active]);

  return (
    <SettingsOpenerContainer ref={openerRef}>
      <SettingsOpenerButton onClick={() => setActive(!active)}>
        <SettingsCogIcon />
      </SettingsOpenerButton>
      {transitions.map(
        ({ item, key, props: style }) =>
          item && (
            <animated.div key={key} style={style}>
              <Modal>
                <SettingsOpenerModal />
              </Modal>
            </animated.div>
          ),
      )}
    </SettingsOpenerContainer>
  );
};
