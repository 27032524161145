import {
  EditorState,
  DraftEditorCommand,
  SelectionState,
} from 'draft-js';
import { ConnectedSocketClientSelection } from '../../shared/socket/client';

export type InlineEditorCommand = DraftEditorCommand | 'indent-line';

export const getCurrentLine = (editorState: EditorState) => {
  const anchorOffset = editorState.getSelection().getAnchorOffset();
  const selection = editorState.getSelection().merge({ anchorOffset }) as SelectionState;
  const key = editorState.getSelection().getStartKey();

  return editorState
    .getCurrentContent()
    .getBlockForKey(key)
    .getText()
    .slice(0, selection.getFocusOffset());
};

export function buildShareableSelection(
  selectionState: SelectionState,
): ConnectedSocketClientSelection | null {
  if (!selectionState.getHasFocus()) {
    return null;
  }
  return {
    anchorKey: selectionState.getAnchorKey(),
    anchorOffset: selectionState.getAnchorOffset(),
    focusKey: selectionState.getFocusKey(),
    focusOffset: selectionState.getFocusOffset(),
  };
}
