import { DraftHandleValue, EditorState } from 'draft-js';
import { handleLink } from '../modifiers/handleLink';
import { handleHeading } from '../modifiers/handleHeading';
import { handleInlineStyle } from '../modifiers/handleInlineStyle';
import { handleList } from '../modifiers/handleList';

export function handleBeforeInput(
  character: string,
  editorState: EditorState,
  setState: (s: EditorState) => void,
): DraftHandleValue {
  let newEditorState = handleLink(editorState, character);

  if (newEditorState === editorState) {
    newEditorState = handleHeading(editorState, character);
  }

  if (newEditorState === editorState) {
    newEditorState = handleInlineStyle(editorState, character);
  }

  if (newEditorState === editorState) {
    newEditorState = handleList(editorState, character);
  }

  if (newEditorState && editorState !== newEditorState) {
    setState(newEditorState);
    return 'handled';
  }
  return 'not-handled';
}
