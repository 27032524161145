import { BaseAction } from '..';

interface BaseUriResolutionAction<T extends string> extends BaseAction<T> {
  documentUri: string;
}

export type UriResolutionStarted = BaseUriResolutionAction<'URI_RESOLUTION_STARTED'>;
export function uriResolutionStarted(documentUri: string): UriResolutionStarted {
  return {
    type: 'URI_RESOLUTION_STARTED',
    documentUri,
  };
}

export interface UriResolutionResolved extends BaseUriResolutionAction<'URI_RESOLUTION_RESOLVED'> {
  documentId: string;
}
export function uriResolutionResolved(
  documentUri: string,
  documentId: string,
): UriResolutionResolved {
  return {
    type: 'URI_RESOLUTION_RESOLVED',
    documentUri,
    documentId,
  };
}

export type UriResolutionFailed = BaseUriResolutionAction<'URI_RESOLUTION_FAILED'>;
export function uriResolutionFailed(documentUri: string): UriResolutionFailed {
  return {
    type: 'URI_RESOLUTION_FAILED',
    documentUri,
  };
}

export interface UriResolutionAdded extends BaseUriResolutionAction<'URI_RESOLUTION_ADDED'> {
  documentId: string;
}
export function uriResolutionAdded(
  documentUri: string,
  documentId: string,
): UriResolutionAdded {
  return {
    type: 'URI_RESOLUTION_ADDED',
    documentUri,
    documentId,
  };
}

export type UriResolutionsAction =
  | UriResolutionStarted
  | UriResolutionResolved
  | UriResolutionFailed
  | UriResolutionAdded;
