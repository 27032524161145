import { ContentState, convertToRaw } from 'draft-js';
import { DocumentBlock, DocumentBlockType, BlockEntity } from '../../shared/document';
import { Dictionary } from '../lib/Dictionary';

export function convertContentStateToDocumentBlocks(contentState: ContentState): DocumentBlock[] {
  const entityMap = contentState.getEntityMap();
  const { blocks } = convertToRaw(contentState);

  return blocks.map(({ key, type, text, depth, inlineStyleRanges, entityRanges, data }) => {
    const uuidEntityMap: Dictionary<BlockEntity<any>> = {};
    const uuidEntityRanges = entityRanges.map(({ offset, length }) => {
      const entity = contentState.getBlockForKey(key).getEntityAt(offset);
      uuidEntityMap[entity] = entityMap.get(entity);
      return {
        key: entity,
        offset,
        length,
      };
    });

    return {
      key,
      type: type as DocumentBlockType,
      text,
      depth,
      inlineStyleRanges,
      entityRanges: uuidEntityRanges,
      entityMap: uuidEntityMap,
      data,
    };
  });
}
