import { OrderedSet } from 'immutable';
import { EditorState, SelectionState, Modifier } from 'draft-js';
// import removeInlineStyles from './removeInlineStyles';
import { InlineStyleType } from '../../../shared/document';

export const changeCurrentInlineStyle = (
  editorState: EditorState,
  matchArr: RegExpMatchArray,
  style: InlineStyleType,
) => {
  const currentContent = editorState.getCurrentContent();
  const selection = editorState.getSelection();
  const key = selection.getStartKey();
  const { index } = matchArr;
  const blockMap = currentContent.getBlockMap();
  const block = blockMap.get(key);
  if (index === undefined) {
    return editorState;
  }

  const currentInlineStyle = block.getInlineStyleAt(index);
  const hasCodeStyle = currentInlineStyle.find((s) => s === 'CODE');
  if (hasCodeStyle) {
    return editorState;
  }
  const focusOffset = index + matchArr[0].length;

  const wordSelection = SelectionState.createEmpty(key).merge({
    anchorOffset: index,
    focusOffset,
  }) as SelectionState;

  let newEditorState = editorState;
  // remove all styles if applying code style
  // if (style === 'CODE') {
  //   newEditorState = removeInlineStyles(newEditorState, wordSelection);
  // }

  const markdownCharacterLength = (matchArr[0].length - matchArr[1].length) / 2;

  let newContentState = newEditorState.getCurrentContent();

  // remove markdown delimiter at end
  newContentState = Modifier.removeRange(
    newContentState,
    wordSelection.merge({
      anchorOffset: wordSelection.getFocusOffset() - markdownCharacterLength,
    }) as SelectionState,
    'forward',
  );

  let afterSelection = newContentState.getSelectionAfter();

  afterSelection = afterSelection.merge({
    anchorOffset: afterSelection.getFocusOffset() - markdownCharacterLength,
    focusOffset: afterSelection.getFocusOffset() - markdownCharacterLength,
  }) as SelectionState;

  // remove markdown delimiter at start
  newContentState = Modifier.removeRange(
    newContentState,
    wordSelection.merge({
      focusOffset: wordSelection.getAnchorOffset() + markdownCharacterLength,
    }) as SelectionState,
    'forward',
  );

  // apply style
  newContentState = Modifier.applyInlineStyle(
    newContentState,
    wordSelection.merge({
      anchorOffset: index,
      focusOffset: focusOffset - markdownCharacterLength * 2,
    }) as SelectionState,
    style,
  );

  newEditorState = EditorState.push(editorState, newContentState, 'change-inline-style');

  return EditorState.setInlineStyleOverride(
    EditorState.forceSelection(newEditorState, afterSelection),
    OrderedSet.of(''),
  );
};
