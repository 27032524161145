import * as React from 'react';
import styled from 'styled-components';
import copyToClipboard from 'copy-to-clipboard';
import { PrivacyIcon } from '../icons/PrivacyIcon';
import { ToggleInput } from '../ToggleInput';
import { useDispatch } from '../../redux';
import { documentPermissionsChanged } from '../../redux/actions/document';

const APP_URL = process.env.APP_URL!;

const ShareOpenerModalStyled = styled.div``;

const Title = styled.h2`
  font-weight: 500;
  font-size: 15px;
`;

const UrlInputContainer = styled.div`
  position: relative;
  height: 50px;
  margin: 12px 0;

  background: ${(props) => props.theme.shareModal.urlBackground};
  border-radius: 8px;
  overflow: hidden;
`;

const UrlInput = styled.input`
  height: 100%;
  width: 100%;
  outline: none;
  background: none;
  border: none;
  font-size: 14px;
  padding: 0 16px;
  cursor: text;
`;

const TransparentFade = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 130px;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    ${(props) => props.theme.shareModal.urlBackground} 40%,
    ${(props) => props.theme.shareModal.urlBackground} 100%
  );
  pointer-events: none;
`;

const CopyButton = styled.button<{ copied: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  outline: none;
  background: none;
  color: ${(props) => props.theme.shareModal.urlCopy};
  border: none;
  font-size: 14px;
  padding: 0 16px;
  cursor: pointer;
  transition: color 0.1s linear;

  ${(props) =>
    props.copied &&
    `
    color: ${props.theme.shareModal.urlCopySuccess};
  `}
`;

const SettingToggle = styled.div`
  display: flex;
`;

const SettingIconBubble = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  width: 36px;
  height: 36px;
  margin-right: 12px;
  background: ${(props) => props.theme.shareModal.settingIconBubble};

  svg {
    fill: ${(props) => props.theme.shareModal.settingIcon};
  }
`;

const SettingText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  max-width: 185px;
  line-height: 16px;
  color: ${(props) => props.theme.shareModal.settingText};
`;

const SettingSwitch = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SettingSpacer = styled.div`
  flex-grow: 1;
`;

interface Props {
  documentId: string;
  publicUri: string;
  urlSafeTitle: string;
  isDocumentOwner: boolean;
  editableByOthers: boolean;
}

export const ShareOpenerModal: React.FunctionComponent<Props> = ({
  documentId,
  publicUri,
  urlSafeTitle,
  isDocumentOwner,
  editableByOthers,
}) => {
  const [copied, setCopied] = React.useState(false);
  const dispatch = useDispatch();

  const shareUrl = `${APP_URL}/d/${publicUri}/${urlSafeTitle}`;

  const copy = () => {
    setCopied(false);
    setTimeout(() => {
      copyToClipboard(shareUrl);
      setCopied(true);
    }, 50);
  };

  return (
    <ShareOpenerModalStyled>
      <Title>Share document</Title>
      <UrlInputContainer>
        <UrlInput id='publicurl' value={shareUrl} disabled={true} />
        <TransparentFade />
        <CopyButton copied={copied} onClick={copy}>
          {copied ? 'COPIED' : 'COPY'}
        </CopyButton>
      </UrlInputContainer>
      <SettingToggle>
        <SettingIconBubble>
          <PrivacyIcon />
        </SettingIconBubble>
        <SettingText>Anyone with the link can edit this document</SettingText>
        <SettingSpacer />
        <SettingSwitch>
          <ToggleInput
            value={editableByOthers || false}
            disabled={!isDocumentOwner}
            onChange={(v) => dispatch(documentPermissionsChanged(documentId, v))}
          />
        </SettingSwitch>
      </SettingToggle>
    </ShareOpenerModalStyled>
  );
};
