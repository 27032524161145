import { ContentState, ContentBlock, convertFromRaw } from 'draft-js';
import { DocumentBlock, BlockEntity } from '../../shared/document';
import { Dictionary } from '../lib/Dictionary';

export function createContentState(blocks: DocumentBlock[]): ContentState {
  let entityMap: Dictionary<BlockEntity<any>> = {};
  blocks.forEach((block) => {
    entityMap = {
      ...entityMap,
      ...block.entityMap,
    };
  });
  return convertFromRaw({
    blocks: blocks as any,
    entityMap,
  });
}
