import * as React from 'react';
import styled from 'styled-components';
import { Link } from './Link';

const FullPageCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100vh;
`;

const MessageContainer = styled.div`
  width: 270px;
  text-align: center;
`;

const Message = styled.h1`
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 16px;
`;

const NewDocumentButton = styled.button`
  cursor: pointer;
  outline: none;
  color: ${(props) => props.theme.shareOpener.text};
  background-color: ${(props) => props.theme.shareOpener.default};
  border: 1px solid ${(props) => props.theme.shareOpener.border};
  outline: none;
  border-radius: 8px;
  padding: 7px 16px;
  font-size: 13px;
  font-weight: 500;
  transition: background-color 0.2s linear;
  text-transform: uppercase;

  &:hover {
    background-color: ${(props) => props.theme.shareOpener.hover};
  }
`;

export const FailedToLoadDocument: React.FunctionComponent = (props) => {
  return (
    <FullPageCenter>
      <MessageContainer>
        <Message>The Document you are looking for doesn't exist</Message>
        <Link href='/'>
          <NewDocumentButton>Create new Document</NewDocumentButton>
        </Link>
      </MessageContainer>
    </FullPageCenter>
  );
};
