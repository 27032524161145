import LinkifyLib, { Rule } from 'linkify-it';

const linkifyIt = LinkifyLib();
linkifyIt.tlds(['com', 'net', 'org', 'info', 'gov', 'co', 'io', 'uk']);
linkifyIt.set({
  fuzzyEmail: false,
});
linkifyIt.add('ftp:', null as any);
linkifyIt.add('mailto:', null as any);
linkifyIt.add('//', null as any);

export default linkifyIt;
