import { EditorState, RichUtils, SelectionState, Modifier } from 'draft-js';

export const insertLink = (editorState: EditorState, matchArr: RegExpMatchArray) => {
  const currentContent = editorState.getCurrentContent();
  const selection = editorState.getSelection();
  const key = selection.getStartKey();
  const [matchText, text, href, title] = matchArr;
  const index = matchArr.index!;
  const focusOffset = index + matchText.length;
  const wordSelection = SelectionState.createEmpty(key).merge({
    anchorOffset: index,
    focusOffset,
  }) as SelectionState;
  const nextContent = currentContent.createEntity('LINK', 'MUTABLE', {
    href,
    title,
    url: href,
  });
  const entityKey = nextContent.getLastCreatedEntityKey();
  let newContentState = Modifier.replaceText(
    nextContent,
    wordSelection,
    text,
    undefined,
    entityKey,
  );
  newContentState = Modifier.insertText(newContentState, newContentState.getSelectionAfter(), ' ');
  const newWordSelection = wordSelection.merge({
    focusOffset: index + text.length,
  }) as SelectionState;
  let newEditorState = EditorState.push(editorState, newContentState, 'apply-entity');
  newEditorState = RichUtils.toggleLink(newEditorState, newWordSelection, entityKey);
  return EditorState.forceSelection(newEditorState, newContentState.getSelectionAfter());
};
