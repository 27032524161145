import { EditorState } from 'draft-js';
import { insertHeading } from './insertHeading';

export const handleHeading = (editorState: EditorState, character: string): EditorState => {
  if (character !== ' ') {
    return editorState;
  }

  const selectionState = editorState.getSelection();
  const offset = selectionState.getStartOffset();
  if (offset > 3) {
    return editorState;
  }

  const key = selectionState.getStartKey();
  const block = editorState.getCurrentContent().getBlockForKey(key);

  if (block.getType() !== 'unstyled') {
    return editorState;
  }

  const text = block.getText().substr(0, offset);

  if (text === '#' || text === '##' || text === '###') {
    return insertHeading(editorState, offset);
  }

  return editorState;
};
