import * as React from 'react';
import styled from 'styled-components';

const ToggleInputStyled = styled.label<{ disabled: boolean }>`
  display: inline-block;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  user-select: none;

  i {
    position: relative;
    display: inline-block;
    margin-right: 0.5rem;
    width: 46px;
    height: 26px;
    background-color: ${(props) => props.theme.toggle.track};
    border-radius: 23px;
    vertical-align: text-bottom;
    transition: all 0.2s linear;
  }

  i::after {
    content: '';
    position: absolute;
    left: 0;
    width: 22px;
    height: 22px;
    background-color: ${(props) => props.theme.toggle.handle};
    border-radius: 11px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
    transform: translate3d(2px, 2px, 0);
    transition: all 0.2s ease-in-out;
  }

  input {
    display: none;
  }

  input:checked + i {
    background-color: ${(props) => props.theme.toggle.active};
  }

  input:checked + i::after {
    transform: translate3d(22px, 2px, 0);
  }

  ${(props) =>
    props.disabled &&
    `
    cursor: not-allowed;
    opacity: 0.65;
  `}
`;

interface Props {
  value: boolean;
  disabled?: boolean;
  onChange: (value: boolean) => void;
}

export const ToggleInput: React.FunctionComponent<Props> = (props) => {
  const onClick = () => {
    if (props.disabled) {
      return;
    }
    props.onChange(!props.value);
  };

  return (
    <ToggleInputStyled disabled={props.disabled || false}>
      <input type='checkbox' disabled={props.disabled} checked={props.value} onChange={onClick} />
      <i />
    </ToggleInputStyled>
  );
};
