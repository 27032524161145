import React from 'react';
import Popover from 'react-tiny-popover';
import styled from 'styled-components';
import linkifyIt from '../../utils/linkifyIt';
import { ContentBlock, DraftDecorator } from 'draft-js';

type Cb = (start: number, end: number) => void;

const strategy = (contentBlock: ContentBlock, callback: Cb) => {
  const links = linkifyIt.match(contentBlock.get('text'));
  if (typeof links !== 'undefined' && links !== null) {
    for (let i = 0; i < links.length; i += 1) {
      callback(links[i].index, links[i].lastIndex);
    }
  }
};

const ClickPopover = styled.div`
  z-index: 50;
  position: relative;
  font-size: 0.7em;
  padding: 4px 5px;
  border-radius: 3px;
  background-color: ${(props) => props.theme.tooltip.background};
  color: ${(props) => props.theme.tooltip.text};
`;

export const RawLinkDecorator: React.FunctionComponent<any> = (props) => {
  const [isPopoverOpen, setPopover] = React.useState(false);
  const links = linkifyIt.match(props.decoratedText);
  const href = links && links[0] ? links[0].url : '#';

  const onClick = (e: React.MouseEvent) => {
    if (e.metaKey || e.ctrlKey) {
      setPopover(false);
      window.open(href, '_blank');
    }
  };

  return (
    <Popover
      isOpen={isPopoverOpen}
      position={'top'}
      padding={0}
      content={<ClickPopover>⌘ + click to navigate</ClickPopover>}
    >
      <a
        href={href}
        target='_blank'
        onClick={onClick}
        onMouseEnter={() => setPopover(true)}
        onMouseLeave={() => setPopover(false)}
      >
        {props.children}
      </a>
    </Popover>
  );
};

export const rawLinkDecorator: DraftDecorator = {
  strategy,
  component: RawLinkDecorator,
};
