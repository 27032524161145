import { EditorState, Modifier } from 'draft-js';
import { insertText } from './insertText';
import { changeCurrentInlineStyle } from './changeCurrentInlineStyle';
import { InlineStyleType } from '../../../shared/document';
import { getCurrentLine } from '../utils';

const inlineMatchers: { [key: string]: RegExp } = {
  BOLD: /(?:^|\s)\*(.+)\*$/g,
  ITALIC: /(?:^|\s)_(.+)_$/g,
  CODE: /(?:^|\s)`([^`]+)`$/g,
  STRIKETHROUGH: /(?:^|\s)~(.+)~$/g,
};

const handleChange = (editorState: EditorState, line: string) => {
  let newEditorState = editorState;
  for (const inlineStyle in inlineMatchers) {
    const re = inlineMatchers[inlineStyle];
    let matchArr;
    do {
      matchArr = re.exec(line);
      if (matchArr) {
        if (matchArr[0][0].match(/^\s/)) {
          matchArr[0] = matchArr[0].replace(/^\s/, '');
          matchArr.index += 1;
        }
        newEditorState = changeCurrentInlineStyle(
          newEditorState,
          matchArr,
          inlineStyle as InlineStyleType,
        );
      }
    } while (matchArr);
    if (newEditorState !== editorState) {
      return newEditorState;
    }
  }

  return editorState;
};

export const handleInlineStyle = (
  editorStateWithoutCharacter: EditorState,
  character: string,
): EditorState => {
  const editorState = insertText(editorStateWithoutCharacter, character);
  let selection = editorState.getSelection();
  let line = getCurrentLine(editorState);
  let newEditorState = handleChange(editorState, line);
  let lastEditorState = editorState;

  // Recursively resolve markdown, e.g. _*text*_ should turn into both italic and bold
  while (newEditorState !== lastEditorState) {
    lastEditorState = newEditorState;
    line = getCurrentLine(newEditorState);
    newEditorState = handleChange(newEditorState, line);
  }

  if (newEditorState !== editorState) {
    let newContentState = newEditorState.getCurrentContent();
    selection = newEditorState.getSelection();

    if (character === '\n') {
      newContentState = Modifier.splitBlock(newContentState, selection);
    }

    newEditorState = EditorState.push(newEditorState, newContentState, 'change-inline-style');

    return newEditorState;
  }

  return editorStateWithoutCharacter;
};
