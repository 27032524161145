import * as React from 'react';
import { DraftHandleValue, EditorState, RichUtils } from 'draft-js';
import { splitBlockAndChange } from '../modifiers/splitBlockAndChange';

export function handleReturn(
  e: React.KeyboardEvent,
  editorState: EditorState,
  setState: (s: EditorState) => void,
): DraftHandleValue {
  if (e.shiftKey) {
    setState(RichUtils.insertSoftNewline(editorState));
    return 'handled';
  }

  const contentState = editorState.getCurrentContent();
  const selection = editorState.getSelection();
  const key = selection.getStartKey();
  const currentBlock = contentState.getBlockForKey(key);
  const type = currentBlock.getType();

  if (/^header-/.test(type)) {
    setState(splitBlockAndChange(editorState, 'unstyled'));
    return 'handled';
  }

  return 'not-handled';
}
