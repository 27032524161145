import * as React from 'react';
import styled from 'styled-components';
import { useSelector } from '../redux';
import { useStore } from 'react-redux';
import { shareNewDocument } from '../redux/stories/shareNewDocument';
import { newDocumentIsSharingSelector } from '../redux/reducers/newDocument';
import { LoadingIcon } from './icons/LoadingIcon';

const ShareLocalButtonStyled = styled.button`
  position: fixed;
  top: 32px;
  right: 32px;
  z-index: 10;

  cursor: pointer;
  outline: none;
  color: ${(props) => props.theme.shareOpener.text};
  background-color: ${(props) => props.theme.shareOpener.default};
  border: 1px solid ${(props) => props.theme.shareOpener.border};
  outline: none;
  border-radius: 8px;
  padding: 7px 16px;
  min-width: 76px;
  font-size: 14px;
  font-weight: 500;
  transition: background-color 0.2s linear;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    top: 16px;
    right: 16px;
  }

  &:hover:not(:disabled) {
    background-color: ${(props) => props.theme.shareOpener.hover};
  }

  &:disabled {
    opacity: 0.7;
    cursor: default;
  }

  svg {
    margin-top: 1px;
    margin-bottom: -3px;
  }
`;

interface Props {
  documentEmpty: boolean;
}

export const ShareLocalButton: React.FunctionComponent<Props> = (props) => {
  const store = useStore();
  const isSharing = useSelector(newDocumentIsSharingSelector);
  const disabled = isSharing || props.documentEmpty;

  const onClick = () => {
    if (disabled) {
      return;
    }
    shareNewDocument(store);
  };

  return (
    <ShareLocalButtonStyled onClick={onClick} disabled={disabled}>
      {isSharing ? <LoadingIcon /> : <>SHARE</>}
    </ShareLocalButtonStyled>
  );
};
