import { BaseAction, AppAction } from '..';
import { SocketState } from '../../socket/SocketState';
import { AuthenticatedSocketClient } from '../../../shared/socket/authentication';
import {
  SubscribedMessage,
  SelectionUpdatedRelayedMessage,
  ContentUpdatedRelayedMessage,
  PermissionsChangedRelayedMessage,
} from '../../../shared/socket/messages';
import { EditorState } from 'draft-js';
import { Document, ContentStateDiff, DocumentBlock } from '../../../shared/document';
import {
  ConnectedSocketClient,
  ConnectedSocketClientSelection,
} from '../../../shared/socket/client';

interface BaseDocumentAction<T extends string> extends BaseAction<T> {
  __documentAction: true;
  documentId: string;
}

export type DocumentOpened = BaseDocumentAction<'DOCUMENT_OPENED'>;
export function documentOpened(documentId: string): DocumentOpened {
  return {
    __documentAction: true,
    type: 'DOCUMENT_OPENED',
    documentId,
  };
}

export type DocumentClosed = BaseDocumentAction<'DOCUMENT_CLOSED'>;
export function documentClosed(documentId: string): DocumentClosed {
  return {
    __documentAction: true,
    type: 'DOCUMENT_CLOSED',
    documentId,
  };
}

export interface DocumentSocketStateChanged
  extends BaseDocumentAction<'DOCUMENT_SOCKET_STATE_CHANGED'> {
  state: SocketState;
}
export function documentSocketStateChanged(
  documentId: string,
  state: SocketState,
): DocumentSocketStateChanged {
  return {
    __documentAction: true,
    type: 'DOCUMENT_SOCKET_STATE_CHANGED',
    documentId,
    state,
  };
}

export type DocumentReconnectAttemptFailed = BaseDocumentAction<
  'DOCUMENT_RECONNECT_ATTEMPT_FAILED'
>;
export function documentReconnectAttemptFailed(documentId: string): DocumentReconnectAttemptFailed {
  return {
    __documentAction: true,
    type: 'DOCUMENT_RECONNECT_ATTEMPT_FAILED',
    documentId,
  };
}

export interface DocumentSocketSubscribed extends BaseDocumentAction<'DOCUMENT_SOCKET_SUBSCRIBED'> {
  auth: AuthenticatedSocketClient;
  connectedClients: ConnectedSocketClient[];
  document: Document;
}
export function documentSocketSubscribed(
  documentId: string,
  message: SubscribedMessage,
): DocumentSocketSubscribed {
  return {
    __documentAction: true,
    type: 'DOCUMENT_SOCKET_SUBSCRIBED',
    documentId,
    auth: message.auth,
    connectedClients: message.connectedClients,
    document: message.document,
  };
}

export interface DocumentClientConnected extends BaseDocumentAction<'DOCUMENT_CLIENT_CONNECTED'> {
  client: ConnectedSocketClient;
}
export function documentClientConnected(
  documentId: string,
  client: ConnectedSocketClient,
): DocumentClientConnected {
  return {
    __documentAction: true,
    type: 'DOCUMENT_CLIENT_CONNECTED',
    documentId,
    client,
  };
}

export interface DocumentClientDisconnected
  extends BaseDocumentAction<'DOCUMENT_CLIENT_DISCONNECTED'> {
  clientId: string;
}
export function documentClientDisconnected(
  documentId: string,
  clientId: string,
): DocumentClientDisconnected {
  return {
    __documentAction: true,
    type: 'DOCUMENT_CLIENT_DISCONNECTED',
    documentId,
    clientId,
  };
}

export interface DocumentClientSelectionUpdated
  extends BaseDocumentAction<'DOCUMENT_CLIENT_SELECTION_UPDATED'> {
  clientId: string;
  selection: ConnectedSocketClientSelection | null;
  newLock: string | null;
}
export function documentClientSelectionUpdated(
  documentId: string,
  message: SelectionUpdatedRelayedMessage,
): DocumentClientSelectionUpdated {
  return {
    __documentAction: true,
    type: 'DOCUMENT_CLIENT_SELECTION_UPDATED',
    documentId,
    clientId: message.clientId,
    selection: message.selection,
    newLock: message.newLock,
  };
}

export interface DocumentClientContentChange
  extends BaseDocumentAction<'DOCUMENT_CLIENT_CONTENT_CHANGE'> {
  clientId: string;
  diff: ContentStateDiff;
  selection: ConnectedSocketClientSelection | null;
  newLock: string | null;
}
export function documentClientContentChange(
  documentId: string,
  message: ContentUpdatedRelayedMessage,
): DocumentClientContentChange {
  return {
    __documentAction: true,
    type: 'DOCUMENT_CLIENT_CONTENT_CHANGE',
    documentId,
    clientId: message.clientId,
    diff: {
      layout: message.layout,
      blocks: message.blocks,
    },
    selection: message.selection,
    newLock: message.newLock,
  };
}

export interface DocumentEditorStateUpdated
  extends BaseDocumentAction<'DOCUMENT_EDITOR_STATE_UPDATED'> {
  editorState: EditorState;
  diff: ContentStateDiff | null;
  newLock: string | null;
}
export function documentEditorStateUpdated(
  documentId: string,
  editorState: EditorState,
  diff: ContentStateDiff | null,
  newLock: string | null,
): DocumentEditorStateUpdated {
  return {
    __documentAction: true,
    type: 'DOCUMENT_EDITOR_STATE_UPDATED',
    documentId,
    editorState,
    diff,
    newLock,
  };
}

export interface DocumentPermissionsChanged
  extends BaseDocumentAction<'DOCUMENT_PERMISSIONS_CHANGED'> {
  isOpen: boolean;
}
export function documentPermissionsChanged(
  documentId: string,
  isOpen: boolean,
): DocumentPermissionsChanged {
  return {
    __documentAction: true,
    type: 'DOCUMENT_PERMISSIONS_CHANGED',
    documentId,
    isOpen,
  };
}

export interface DocumentClientPermissionsChanged
  extends BaseDocumentAction<'DOCUMENT_CLIENT_PERMISSIONS_CHANGED'> {
  clientId: string;
  isOpen: boolean;
}
export function documentClientPermissionsChanged(
  documentId: string,
  message: PermissionsChangedRelayedMessage,
): DocumentClientPermissionsChanged {
  return {
    __documentAction: true,
    type: 'DOCUMENT_CLIENT_PERMISSIONS_CHANGED',
    documentId,
    clientId: message.clientId,
    isOpen: message.isOpen,
  };
}

export type DocumentAction =
  | DocumentOpened
  | DocumentClosed
  | DocumentSocketStateChanged
  | DocumentReconnectAttemptFailed
  | DocumentSocketSubscribed
  | DocumentClientConnected
  | DocumentClientDisconnected
  | DocumentClientSelectionUpdated
  | DocumentClientContentChange
  | DocumentEditorStateUpdated
  | DocumentPermissionsChanged
  | DocumentClientPermissionsChanged;

export function isDocumentAction(action: AppAction): action is DocumentAction {
  return '__documentAction' in action;
}
