import * as React from 'react';

export const LogoIcon: React.FunctionComponent = () => {
  return (
    <svg
      width='120'
      height='120'
      viewBox='0 0 120 120'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 60C33.1371 60 60 33.1371 60 0C60 33.1371 86.8629 60 120 60C86.8629 60 60 86.8629 60 120C60 86.8629 33.1371 60 0 60Z'
      />
    </svg>
  );
};
