import React from 'react';
import Popover from 'react-tiny-popover';
import styled from 'styled-components';
import { ContentBlock, DraftDecorator, ContentState } from 'draft-js';

type Cb = (start: number, end: number) => void;

const strategy = (contentBlock: ContentBlock, callback: Cb, contentState: ContentState) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK';
  }, callback);
};

const ClickPopover = styled.div`
  z-index: 50;
  position: relative;
  font-size: 0.7em;
  padding: 4px 5px;
  border-radius: 3px;
  background-color: ${(props) => props.theme.tooltip.background};
  color: ${(props) => props.theme.tooltip.text};
`;

export const EntityLinkDecorator: React.FunctionComponent<any> = (props) => {
  const [isPopoverOpen, setPopover] = React.useState(false);
  const { url } = props.contentState.getEntity(props.entityKey).getData();

  const onClick = (e: React.MouseEvent) => {
    if (e.metaKey || e.ctrlKey) {
      setPopover(false);
      window.open(url, '_blank');
    }
  };

  return (
    <Popover
      isOpen={isPopoverOpen}
      position={'top'}
      padding={0}
      content={<ClickPopover>⌘ + click to navigate</ClickPopover>}
    >
      <a
        href={url}
        target='_blank'
        onClick={onClick}
        onMouseEnter={() => setPopover(true)}
        onMouseLeave={() => setPopover(false)}
      >
        {props.children}
      </a>
    </Popover>
  );
};

export const entityLinkDecorator: DraftDecorator = {
  strategy,
  component: EntityLinkDecorator,
};
