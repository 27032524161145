import { AppStore } from '..';
import { newDocumentShareStarted, newDocumentShareSuccess } from '../actions/newDocument';
import { newDocumentSelector } from '../reducers/newDocument';
import { apiClient } from '../../api/InlineAPIClient';
import { convertContentStateToDocumentBlocks } from '../../utils/convertContentStateToDocumentBlocks';
import { authenticationTokenReceived } from '../actions/authentication';
import history from '../../history';
import { urlifyTitle, extractTitle } from '../../utils/documentTitle';
import { documentOpened } from '../actions/document';
import { SocketState } from '../../socket/SocketState';
import { uriResolutionAdded } from '../actions/uriResolutions';

export async function shareNewDocument(store: AppStore): Promise<void> {
  store.dispatch(newDocumentShareStarted());

  const { editorState } = newDocumentSelector(store.getState());
  const title = extractTitle(editorState.getCurrentContent());
  try {
    const { document, token } = await apiClient.createDocument(
      convertContentStateToDocumentBlocks(editorState.getCurrentContent()),
    );
    if (token) {
      store.dispatch(authenticationTokenReceived(token));
    }
    store.dispatch(uriResolutionAdded(document.publicUri, document.id));
    store.dispatch(documentOpened(document.id));
    const unsub = store.subscribe(() => {
      if (store.getState().document.socketState === SocketState.CONNECTED) {
        unsub();
        store.dispatch(newDocumentShareSuccess(document));
        history.push(`/d/${document.publicUri}/${urlifyTitle(title)}`);
      }
    });
  } catch (e) {
    console.error(e);
  }
}
