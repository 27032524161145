import * as React from 'react';
import styled from 'styled-components';
import { LoadingIcon } from './icons/LoadingIcon';

const FullPageCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100vh;
`;

export const LoadingDocument: React.FunctionComponent = (props) => {
  return (
    <FullPageCenter>
      <div>
        <LoadingIcon />
      </div>
    </FullPageCenter>
  );
};
