import * as React from 'react';
import history from '../history';
import { useSelector } from '../redux';
import { rawPathSelector } from '../redux/reducers/router';

interface Props {
  href: string;
  children: React.ReactNode;
}

export const Link: React.FunctionComponent<Props> = (props) => {
  const currentUrl = useSelector(rawPathSelector);

  const onClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (currentUrl !== props.href) {
      history.push(props.href);
    }
  };

  return (
    <a href={props.href} onClick={onClick}>
      {props.children}
    </a>
  );
};
