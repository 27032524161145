export enum DocumentBlockType {
  UNSTYLED = 'unstyled',
  PARAGRAPH = 'paragraph',
  HEADER_ONE = 'header-one',
  HEADER_TWO = 'header-two',
  HEADER_THREE = 'header-three',
  UNORDERED_LIST_ITEM = 'unordered-list-item',
  ORDERED_LIST_ITEM = 'ordered-list-item',
  CODE_BLOCK = 'code-block',
  ATOMIC = 'atomic',
}

export type InlineStyleType = 'BOLD' | 'CODE' | 'ITALIC' | 'STRIKETHROUGH' | 'UNDERLINE';

export enum EntityType {
  LINK = 'LINK',
  TOKEN = 'TOKEN',
  PHOTO = 'PHOTO',
  IMAGE = 'IMAGE',
}

export enum EntityMutability {
  MUTABLE = 'MUTABLE',
  IMMUTABLE = 'IMMUTABLE',
  SEGMENTED = 'SEGMENTED',
}

export interface InlineStyleRange {
  style: InlineStyleType;
  offset: number;
  length: number;
}

export interface BlockEntity<T> {
  type: EntityType;
  mutability: EntityMutability;
  data: T;
}

export type BlockEntityMap = { [key: string]: BlockEntity<any> };

export interface EntityRange {
  key: string;
  offset: number;
  length: number;
}

export interface DocumentBlock {
  key: string;
  type: DocumentBlockType;
  text: string;
  depth: number;
  inlineStyleRanges: InlineStyleRange[];
  entityRanges: EntityRange[];
  entityMap: BlockEntityMap;
  data?: Object;
}

export interface Document {
  id: string;
  userId: string;
  publicUri: string;
  isOpen: boolean;
  blocks: DocumentBlock[];
}

export interface ContentStateDiff {
  layout: DocumentLayoutDiff | null;
  blocks: DocumentBlock[];
}

export interface DocumentLayoutDiff {
  added: [string | null, string][];
  removed: string[];
}
