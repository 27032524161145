import { Location } from 'history';
import { BaseAction } from '../';

export interface RouterUrlUpdated extends BaseAction<'ROUTER_URL_UPDATED'> {
  location: Location;
}

export function routerUrlUpdated(location: Location): RouterUrlUpdated {
  return {
    type: 'ROUTER_URL_UPDATED',
    location,
  };
}

export type RouterAction = RouterUrlUpdated;
