import { DefaultTheme } from 'styled-components';
import { ThemeName } from './ThemeName';
import { lighten, darken } from 'polished';

export const lightTheme: DefaultTheme = {
  name: ThemeName.LIGHT,

  breakpoints: {
    narrow: 1150,
    tablet: 800,
    mobile: 425,
  },

  background: '#F7F7F7',
  primary: '#5A71D8',

  typography: {
    default: '#222222',
    placeholder: '#9197A3',
  },

  modal: {
    background: '#FFF',
    text: '#222222',
    iconBackground: '#545464',
  },

  tooltip: {
    background: '#222222',
    text: '#FFF',
  },

  settingsOpener: {
    background: '#FFF',
    hover: darken(0.03)('#FFF'),
    border: '#E1E1E1',
    text: '#5A71D8',
  },

  shareOpener: {
    default: '#FFF',
    hover: darken(0.03)('#FFF'),
    border: '#E1E1E1',
    text: '#5A71D8',
  },

  shareModal: {
    urlBackground: '#F1F2F5',
    urlText: '#222',
    urlCopy: '#5A71D8',
    urlCopySuccess: '#5EBB3B',
    settingIconBubble: '#F1F2F5',
    settingIcon: '#5F666F',
    settingText: '#222',
  },

  primaryButton: {
    default: '#5A71D8',
    hover: lighten(0.05)('#5A71D8'),
    disabled: lighten(0.06)('#5A71D8'),
    text: '#FFF',
  },

  toggle: {
    track: '#545464',
    handle: '#FFF',
    active: '#5A71D8',
  },
};
