import { EditorState, Modifier, SelectionState } from 'draft-js';

const headingMap = new Map([
  [1, 'header-one'],
  [2, 'header-two'],
  [3, 'header-three'],
]);

export function insertHeading(editorState: EditorState, level: number): EditorState {
  const selectionState = editorState.getSelection();
  let newContentState = Modifier.setBlockType(
    editorState.getCurrentContent(),
    selectionState,
    headingMap.get(level)!,
  );

  newContentState = Modifier.removeRange(
    newContentState,
    selectionState.merge({
      anchorOffset: 0,
    }) as SelectionState,
    'forward',
  );

  return EditorState.push(editorState, newContentState, 'change-block-type');
}
