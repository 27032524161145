import * as React from 'react';

export const LockIcon: React.FunctionComponent = () => {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g filter='url(#filter0_d)'>
        <circle cx='8' cy='7' r='6' fill='white' />
      </g>
      <path
        d='M10.6665 6.33333H9.99984V5C9.99984 4.46957 9.78912 3.96086 9.41405 3.58579C9.03898 3.21071 8.53027 3 7.99984 3C7.4694 3 6.9607 3.21071 6.58562 3.58579C6.21055 3.96086 5.99984 4.46957 5.99984 5V6.33333H5.33317C5.15636 6.33333 4.98679 6.40357 4.86177 6.5286C4.73674 6.65362 4.6665 6.82319 4.6665 7V10.3333C4.6665 10.5101 4.73674 10.6797 4.86177 10.8047C4.98679 10.9298 5.15636 11 5.33317 11H10.6665C10.8433 11 11.0129 10.9298 11.1379 10.8047C11.2629 10.6797 11.3332 10.5101 11.3332 10.3333V7C11.3332 6.82319 11.2629 6.65362 11.1379 6.5286C11.0129 6.40357 10.8433 6.33333 10.6665 6.33333ZM8.6665 6.33333H7.33317V5C7.33317 4.82319 7.40341 4.65362 7.52843 4.5286C7.65346 4.40357 7.82303 4.33333 7.99984 4.33333C8.17665 4.33333 8.34622 4.40357 8.47124 4.5286C8.59627 4.65362 8.6665 4.82319 8.6665 5V6.33333Z'
        fill='#5F666F'
      />
      <defs>
        <filter
          id='filter0_d'
          x='0'
          y='0'
          width='16'
          height='16'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          />
          <feOffset dy='1' />
          <feGaussianBlur stdDeviation='1' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow' result='shape' />
        </filter>
      </defs>
    </svg>
  );
};
