import * as React from 'react';
import styled from 'styled-components';
import { ConnectedClient } from './ConnectedClients/ConnectedClient';
import { useSelector } from '../redux';
import { documentOtherConnectedClientsSelector } from '../redux/reducers/document';
import { ConnectedSocketClient } from '../../shared/socket/client';

const StyledConnectedClients = styled.ul`
  position: fixed;
  top: 32px;
  right: 125px;

  text-align: right;
  height: 36px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    top: 16px;
    right: 145px;
  }
`;

interface Props {
  connectedClients: ConnectedSocketClient[];
}

export const ConnectedClients: React.FunctionComponent<Props> = ({ connectedClients }) => {
  return (
    <StyledConnectedClients>
      {connectedClients.map((client) => (
        <ConnectedClient key={client.id} client={client} />
      ))}
    </StyledConnectedClients>
  );
};
