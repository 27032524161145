import { AppAction, AppState } from '..';
import { produce } from 'immer';
import { Map as immutableMap } from 'immutable';
import { createSelector } from 'reselect';
import { update } from '../../lib/updater';
import { AuthenticatedSocketClient } from '../../../shared/socket/authentication';
import { SocketState } from '../../socket/SocketState';
import { isDocumentAction } from '../actions/document';
import { checkNever, notMissing } from '../../lib/typeGuards';
import { convertToRaw, Editor, EditorState, Modifier, SelectionState } from 'draft-js';
import {
  ConnectedSocketClient,
  ConnectedSocketClientSelection,
} from '../../../shared/socket/client';
import { applyContentStateDiff } from '../../utils/applyContentStateDiff';
import { createContentState } from '../../utils/createContentState';
import { inlineDraftDecorator } from '../../draft/decorator';

export interface DocumentState {
  documentId: string | null;
  socketState: SocketState;
  reconnectAttempts: number;
  auth: AuthenticatedSocketClient | null;
  connectedClients: Map<string, ConnectedSocketClient>;
  publicUri: string | null;
  userId: string | null;
  editableByOthers: boolean | null;
  editorState: EditorState | null;
  currentLock: string | null;
  locks: Map<string, string>;
}

export function initialDocumentState(): DocumentState {
  return {
    documentId: null,
    socketState: SocketState.DISCONNECTED,
    reconnectAttempts: 0,
    auth: null,
    connectedClients: new Map(),
    publicUri: null,
    editableByOthers: null,
    userId: null,
    editorState: null,
    currentLock: null,
    locks: new Map(),
  };
}

export function documentReducer(state: DocumentState, action: AppAction): DocumentState {
  if (!isDocumentAction(action)) {
    return state;
  }

  const documentOpen = notMissing(state.documentId);
  if (!documentOpen) {
    if (action.type === 'DOCUMENT_OPENED') {
      return update(initialDocumentState())
        .get('documentId')
        .set(() => action.documentId);
    } else {
      console.error('DocumentAction was dispatched before document was opened');
      console.log(action);
      return state;
    }
  } else if (documentOpen && state.documentId !== action.documentId) {
    console.error('DocumentAction was dispatched for non active document');
    console.log(action);
    return state;
  }

  switch (action.type) {
    case 'DOCUMENT_OPENED':
      console.error('DOCUMENT_OPENED was dispatched before closing previous document');
      console.log(action);
      return state;
    case 'DOCUMENT_CLOSED':
      return initialDocumentState();
    case 'DOCUMENT_SOCKET_STATE_CHANGED':
      const stateUpdate = update(state)
        .get('socketState')
        .set(() => action.state);
      switch (action.state) {
        case SocketState.CONNECTING:
        case SocketState.RECONNECTING:
        case SocketState.CONNECTED:
          return update(stateUpdate)
            .get('reconnectAttempts')
            .set(() => 0);
        default:
          return stateUpdate;
      }
    case 'DOCUMENT_RECONNECT_ATTEMPT_FAILED':
      return update(state)
        .get('reconnectAttempts')
        .set((cur) => cur + 1);
    case 'DOCUMENT_SOCKET_SUBSCRIBED':
      return produce(state, (draft) => {
        draft.connectedClients = new Map(
          action.connectedClients.map((client) => [client.id, client]),
        );
        const locks = action.connectedClients
          .filter((c) => c.currentLock)
          .map((c) => [c.currentLock, c.id]) as [[string, string]];
        draft.locks = new Map(locks);
        draft.auth = action.auth;
        draft.publicUri = action.document.publicUri;
        draft.editableByOthers = action.document.isOpen;
        draft.userId = action.document.userId;
        draft.editorState = EditorState.createWithContent(
          createContentState(action.document.blocks),
          inlineDraftDecorator,
        );
        draft.editorState = EditorState.set(draft.editorState, {
          allowUndo: false,
        });
      });
    case 'DOCUMENT_CLIENT_CONNECTED':
      return produce(state, (draft) => {
        draft.connectedClients.set(action.client.id, action.client);
      });
    case 'DOCUMENT_CLIENT_DISCONNECTED':
      return produce(state, (draft) => {
        const client = draft.connectedClients.get(action.clientId);
        if (client && client.currentLock) {
          draft.locks.delete(client.currentLock);
        }
        draft.connectedClients.delete(action.clientId);
      });
    case 'DOCUMENT_EDITOR_STATE_UPDATED':
      return produce(state, (draft) => {
        draft.editorState = action.editorState;
        draft.currentLock = action.newLock;
      });
    case 'DOCUMENT_CLIENT_SELECTION_UPDATED':
    case 'DOCUMENT_CLIENT_CONTENT_CHANGE':
      return produce(state, (draft) => {
        const client = draft.connectedClients.get(action.clientId);
        if (client) {
          const oldLock = client.currentLock;
          draft.connectedClients.set(action.clientId, {
            ...client,
            selection: action.selection,
            currentLock: action.newLock,
          });
          if (action.newLock !== oldLock) {
            if (action.newLock) {
              draft.locks.set(action.newLock, action.clientId);
            }
            if (oldLock) {
              draft.locks.delete(oldLock);
            }
          }
        }
        if (action.type === 'DOCUMENT_CLIENT_CONTENT_CHANGE') {
          draft.editorState = applyContentStateDiff(action.diff)(draft.editorState);
        }
      });
    case 'DOCUMENT_PERMISSIONS_CHANGED':
    case 'DOCUMENT_CLIENT_PERMISSIONS_CHANGED':
      return produce(state, (draft) => {
        draft.editableByOthers = action.isOpen;
      });
    default:
      checkNever(action);
      return state;
  }
}

export const documentIsOpenSelector = (state: AppState) => notMissing(state.document.documentId);
export const documentIdSelector = (state: AppState) => state.document.documentId;
export const documentPublicUriSelector = (state: AppState) => state.document.publicUri;
export const documentEditableByOthersSelector = (state: AppState) =>
  state.document.editableByOthers || false;
export const documentOwnerIdSelector = (state: AppState) => state.document.userId;
export const documentClientIdSelector = (state: AppState) => state.document.auth?.clientId;
export const documentAuthUserIdSelector = (state: AppState) => state.document.auth?.userId;
export const documentSocketStateSelector = (state: AppState) => state.document.socketState;
export const documentCurrentLockSelector = (state: AppState) => state.document.currentLock;
export const documentLocksSelector = (state: AppState) => state.document.locks;
export const documentReconnectAttemptsSelector = (state: AppState) =>
  state.document.reconnectAttempts;
export const documentEditorStateSelector = (state: AppState) => state.document.editorState;
export const documentContentStateSelector = (state: AppState) =>
  state.document.editorState?.getCurrentContent()!;
export const documentIsOpenAndConnectedSelector = (state: AppState) =>
  documentIsOpenSelector(state) && documentSocketStateSelector(state) === SocketState.CONNECTED;
export const documentIsOwnerSelector = createSelector(
  documentOwnerIdSelector,
  documentAuthUserIdSelector,
  (ownerId, authUserId) => notMissing(authUserId) && ownerId === authUserId,
);

export const documentCanBeEditedByClientSelector = createSelector(
  documentIsOwnerSelector,
  documentEditableByOthersSelector,
  (isOwner, editableByOthers) => isOwner || editableByOthers,
);

const documentConnectedClientsSelector = (state: AppState) => state.document.connectedClients;
export const documentOtherConnectedClientsSelector = createSelector(
  documentConnectedClientsSelector,
  documentClientIdSelector,
  (clients, selfClientId) => {
    const otherClients: ConnectedSocketClient[] = [];
    clients.forEach((client, clientId) => {
      if (clientId !== selfClientId) {
        otherClients.push(client);
      }
    });
    return otherClients;
  },
);
export const documentEditorStateWithLocksSelector = createSelector(
  documentEditorStateSelector,
  documentLocksSelector,
  documentConnectedClientsSelector,
  documentCanBeEditedByClientSelector,
  (editorState, locks, clients, documentCanBeEditedByClient) => {
    if (!editorState) {
      return editorState;
    }
    let contentState = editorState.getCurrentContent();
    contentState.getBlocksAsArray().forEach((block) => {
      const key = block.getKey();
      const lockingClientId = locks.get(key);

      const data: any = { readOnly: !documentCanBeEditedByClient };
      if (lockingClientId) {
        data.lock = clients.get(lockingClientId);
      } else {
        data.lock = null;
      }

      contentState = Modifier.setBlockData(
        contentState,
        SelectionState.createEmpty(key),
        immutableMap(data),
      );
    });
    return EditorState.set(editorState, { currentContent: contentState });
  },
);
