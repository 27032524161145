import { AppAction, AppState } from '..';
import { Dictionary } from '../../lib/Dictionary';
import { update } from '../../lib/updater';

export enum ResolutionStatus {
  RESOLVING,
  RESOLVED,
  FAILED,
}

interface ResolvingUri {
  status: ResolutionStatus.RESOLVING;
}

interface ResolvedUri {
  status: ResolutionStatus.RESOLVED;
  documentId: string;
  new: boolean;
}

interface FailedUri {
  status: ResolutionStatus.FAILED;
}

type UriResolution = ResolvingUri | ResolvedUri | FailedUri;

export interface UriResolutionsState {
  uriMap: Dictionary<UriResolution>;
}

export function initialUriResolutionsState(): UriResolutionsState {
  return {
    uriMap: {},
  };
}

export function uriResolutionsReducer(
  state: UriResolutionsState,
  action: AppAction,
): UriResolutionsState {
  switch (action.type) {
    case 'URI_RESOLUTION_STARTED':
      return update(state)
        .get('uriMap')
        .get(action.documentUri)
        .set(() => ({ status: ResolutionStatus.RESOLVING }));
    case 'URI_RESOLUTION_RESOLVED':
      return update(state)
        .get('uriMap')
        .get(action.documentUri)
        .set(() => ({ status: ResolutionStatus.RESOLVED, documentId: action.documentId, new: false }));
    case 'URI_RESOLUTION_FAILED':
      return update(state)
        .get('uriMap')
        .get(action.documentUri)
        .set(() => ({ status: ResolutionStatus.FAILED }));
    case 'URI_RESOLUTION_ADDED':
      return update(state)
        .get('uriMap')
        .get(action.documentUri)
        .set(() => ({ status: ResolutionStatus.RESOLVED, documentId: action.documentId, new: true }));
    default:
      return state;
  }
}

export const uriResolutionSelectorCreator = (documentUri: string) => (
  state: AppState,
): UriResolution | undefined => state.uriResolutions.uriMap[documentUri];
