import { SocketServerMessage } from '../../shared/socket/messages';

type ValidMessageHandler = (message: SocketServerMessage) => void;
type InvalidMessageHandler = (data: string) => void;

export function socketMessageHandler(
  onValidMessage: ValidMessageHandler,
  onInvalidMessage: InvalidMessageHandler,
): (event: WebSocketMessageEvent) => void {
  return (event: WebSocketMessageEvent) => {
    const data = event.data as string;
    let parsed;
    try {
      parsed = JSON.parse(data);
    } catch (e) {
      onInvalidMessage(data);
      return;
    }
    onValidMessage(parsed);
  };
}
