import lscache from 'lscache';
import { AppAction, AppState } from '..';
import { update } from '../../lib/updater';
import { ThemeName } from '../../themes/ThemeName';

const THEME_CACHE_KEY = 'theme';

const hasNightModeEnabled = () =>
  window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

export interface AppSettingsState {
  theme: ThemeName;
}

export function initialAppSettingsState(): AppSettingsState {
  const persistedTheme = lscache.get(THEME_CACHE_KEY);
  const systemTheme = hasNightModeEnabled() ? ThemeName.DARK : ThemeName.LIGHT;
  return {
    theme: persistedTheme || systemTheme,
  };
}

export function appSettingsReducer(state: AppSettingsState, action: AppAction): AppSettingsState {
  switch (action.type) {
    case 'APP_SETTINGS_THEME_CHANGED':
      lscache.set(THEME_CACHE_KEY, action.theme, 8 * 60);
      return update(state)
        .get('theme')
        .set(() => action.theme);
    default:
      return state;
  }
}

export const appSettingsThemeSelector = (state: AppState) => state.appSettings.theme;
