import * as React from 'react';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import { useSelector } from '../redux';
import { appSettingsThemeSelector } from '../redux/reducers/appSettings';
import { themes } from '../themes';
import { RouterState, Route, routerSelector } from '../redux/reducers/router';
import { RedirectPage } from '../pages/RedirectPage';
import { NewDocumentPage } from '../pages/NewDocumentPage';
import { SavedDocumentPage } from '../pages/SavedDocumentPage';

const GlobalStyle = createGlobalStyle<{ enableFade: boolean }>`
  body {
    background-color: ${(props) => props.theme.background};
    color: ${(props) => props.theme.typography.default};

    ${(props) =>
      props.enableFade &&
      `
      transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out;
    `}
  }
`;

export const App: React.FunctionComponent = () => {
  const routerState = useSelector(routerSelector);
  const [enableFade, setEnabledFade] = React.useState(false);
  const theme = themes[useSelector(appSettingsThemeSelector)];

  React.useEffect(() => {
    setTimeout(() => setEnabledFade(true), 10);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle enableFade={enableFade} />
      {pageComponent(routerState)}
    </ThemeProvider>
  );
};

function pageComponent(routerState: RouterState): JSX.Element {
  switch (routerState.route) {
    case Route.NewDocument:
      return <NewDocumentPage />;
    case Route.SavedDocument:
      return (
        <SavedDocumentPage documentUri={routerState.documentUri} urlTitle={routerState.urlTitle} />
      );
    default:
      return <RedirectPage url='/' />;
  }
}
